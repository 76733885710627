import { DatePipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MEETING_TYPE_ID } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { CalendarAppointmentModel } from '@models/appointment-booking.model';
import { CustomerDbModel } from '@models/customer.model';
import { PartnerDbModel } from '@models/partner-db.model';
import { SurveyStepModel } from '@models/survey-step.model';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { SurveyService } from '@services/feature/survey.service';
import { WidgetService } from '@services/feature/widget.service';
import { FileService } from '@services/utils/file.service';
import { LoggerService } from '@services/utils/logger.service';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';

@Component({
    selector: 'app-survey-end-step',
    templateUrl: './survey-end-step.component.html',
    styleUrls: ['./survey-end-step.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, CswTextComponent, NgStyle, MatIconModule, DatePipe, TranslateModule, CalioSafePipe, CalioTranslationPipe, CalioMeetingTemplatePipe]
})
export class SurveyEndStepComponent implements OnInit {

  readonly meetingTypeId = MEETING_TYPE_ID;

  lang: string;

  constructor(
    public widgetService: WidgetService,
    public surveyService: SurveyService,
    private fileService: FileService,
    private translate: TranslateService,
    public bookingService: BookingService,
  ) {
  }

  ngOnInit(): void {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(
      (language: LangChangeEvent) => {
        this.lang = language.lang;
      }
    );
  }

  exportSurveySummary(bookingData: CalendarAppointmentModel): void {
    this.surveyService.exportSurveySummary(
      bookingData?.uuid,
      this.surveyService.customerDetail.uuid,
      this.widgetService.widgetUUID
    ).subscribe({
      next: (result: {
        path: string,
        appointment: CalendarAppointmentModel,
        partner: PartnerDbModel,
        customer: CustomerDbModel,
        widgetSteps: SurveyStepModel[]
      }) => {
        this.fileService.saveFile(`${environment.hostName}${result.path}`, this.fileService.fetchFilenameFromPath(result.path));
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }
}
