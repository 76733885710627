import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { CountryDbModel } from 'src/app/models/Country-db.model';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CountryHelperService {
  constructor(
    public translate: TranslateService,
    private helperService: HelperService,
    private httpClientService: HttpClientService
  ) { }

  public createNewCountries(countries: CountryDbModel[]): CountryDbModel[] {
    let newCountries: CountryDbModel[] = [];
    for (const country of countries) {
      const newCountry: CountryDbModel = new CountryDbModel();
      newCountry.id = country.id;
      newCountry.identifier = country.identifier;
      newCountry.name = country.name;
      newCountry.is_multi_language = country.is_multi_language;
      newCountry._translations = country._translations;
      newCountries.push(newCountry);
    }

    newCountries = this.helperService.sortBy(newCountries, 'name');

    return newCountries;
  }

  getCountries(): Observable<CountryDbModel[]> {
    return this.httpClientService.get('countries', {});
  }
}
