import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { IS_CALENSO_AUTH_REQUIRED } from '@constants/http-context';
import { AuthTokenService } from '@services/utils/auth-token.service';
import { LoggerService } from '@services/utils/logger.service';

export const HttpInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authTokenService = inject(AuthTokenService);
  return next(request).pipe(
    filter(
      (event): event is HttpResponse<unknown> => event instanceof HttpResponse
    ),
    map((event: HttpResponse<any>) => {
      const body = event.body;
      if (body?.errors?.[0]) {
        throw new HttpErrorResponse({
          error: new Error(body.errors[0].message),
          statusText: body.errors[0].message,
          status: body.errors[0].code,
        });
      } else if (body?.errors) {
        if (
          body.errors.code === 'malware' ||
          body.errors.code === 'file-size'
        ) {
          throw new HttpErrorResponse({
            error: new Error(body.errors.message),
            statusText: body.errors.code,
            status: 400,
          });
        }
      } else if (body?.error) {
        if (body.message) {
          throw new HttpErrorResponse({
            error: new Error(body.message),
            statusText: body.message,
            status: body.status,
          });
        }
      } else {
        if (request.context.has(IS_CALENSO_AUTH_REQUIRED)) {
          const authToken = event?.body?.token;
          authToken && authTokenService.setToken(authToken);
        }
        return event;
      }
    }),
    catchError((error: HttpErrorResponse) => {
      LoggerService.error(error);
      throw error;
    })
  );
};
