import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { environment } from '@environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { LoggerService } from '@services/utils/logger.service';
import { SentryConfig } from './sentry.config';

if (environment?.production) {
  enableProdMode();
  Sentry.init(SentryConfig);
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  LoggerService.error(err)
);
