<div class="coupon-form-box">
  <form #couponForm="ngForm" class="coupon-form mb-0">
    <div>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'coupon_credit_card_info.coupon' | translate }}"
          [(ngModel)]="coupon"
          name="coupon"
          #couponModel="ngModel"
          id="coupon"
        />
        <button
          [disabled]="!coupon"
          type="submit"
          class="btn btn-secondary redeem-button"
          (click)="onApplyCoupon()"
        >
          {{ "coupon_credit_card_info.redeem_coupon" | translate }}
        </button>
      </div>
      @if (showError) {
        <div id="coupon-errors" class="coupon-errors col-md-12 mt-1" role="alert">
          {{ "coupon_credit_card_info.invalid_coupon" | translate }}
        </div>
      } 
      @if (showSuccessMsg) {
        <div
          id="coupon-success"
          class="coupon-success col-md-12 mt-1"
          role="alert"
        >
          {{ "coupon_credit_card_info.applied_successfully" | translate }}
        </div>
      }
    </div>
  </form>
</div>
