import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppointmentServiceModel} from '../../models/appointment-service.model';
import {HttpClientService} from '../utils/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  get(uuid: string): Observable<AppointmentServiceModel> {
    return this.httpClientService.get(`appointments/view_by_uuid/${uuid}`, {});
  }
}
