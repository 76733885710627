import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { EMAIL_REGEX, ZIP_REGEX } from '@constants/app.constants';
import { TranslateModule } from '@ngx-translate/core';
import { CalioAddRequiredPipe } from '@pipes/calio-add-required.pipe';
import { WidgetService } from '@services/feature/widget.service';

@Component({
    selector: 'app-survey-customer-form',
    templateUrl: './survey-customer-form.component.html',
    styleUrls: ['./survey-customer-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [FormsModule, NgClass, TranslateModule, CalioAddRequiredPipe]
})
export class SurveyCustomerFormComponent implements OnInit {

  disableEmailField = false;
  customerFields = ['prename', 'lastname', 'email'];

  readonly emailRegex = EMAIL_REGEX;
  readonly zipRegex = ZIP_REGEX;

  constructor(
    public widgetService: WidgetService
  ) {
  }

  ngOnInit(): void {
    if (this.widgetService.customerConfData.email) {
      this.disableEmailField = true;
    }

    if (this.widgetService.widgetConf.context?.ask_customer_city === 1) {
      this.customerFields.push('city');
    }

    if (this.widgetService.widgetConf.context?.ask_customer_zip === 1) {
      this.customerFields.push('zip');
    }
  }
}
