import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl,
} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@services/utils/logger.service';

@Pipe({
  name: 'calioSafe',
  standalone: true,
})
export class CalioSafePipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
    public translate: TranslateService
  ) {}

  public transform(
    value: any,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (
      /<(basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|wbr|!DOCTYPE|abbr|acronym|address|applet|article|aside|audio|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|html|iframe|ins|kbd|keygen|label|legend|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|var|video) .+?>/g.test(
        value
      )
    ) {
      LoggerService.log('Blacklisted Html tag is founded ', value);
      return `<span class="vulnerable-error-msg">${this.translate.instant(
        'error_messages.xss_error_message'
      )}</span>`; // Error message
    }

    // Anchor tags
    if (
      /<(a|b|i|u|br|div|p|ul|li|span|strike|strong|img|h1|h2|h3|h4|h5|h6) .+?>/g.test(
        value
      )
    ) {
      LoggerService.log('Whitelisted tag is founded ', value);
      try {
        if (value.indexOf('javascript:') > -1) {
          LoggerService.log('Anchor tag javascript function is founded');
          return `<span class="vulnerable-error-msg">${this.translate.instant(
            'general.xss_error_message'
          )}</span>`; // Error message
        }
      } catch (e) {}
    }
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
