import { DatePipe, NgClass, NgStyle, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DATE_ISO_FORMAT, DATE_TIME_ISO_T_FORMAT } from '@constants/date.constants';
import { environment } from '@environments/environment';
import { AppointmentCartItem } from '@models/selected-booking-data.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomEventService } from '@services/feature/custom-event.service';
import { DateUtilService } from '@services/utils/date-util.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { LoaderComponent } from '@ui-lib/loader/loader.component';
import { CalendarCommonModule, CalendarMonthModule, CalendarMonthViewDay, CalendarUtils } from 'angular-calendar';
import { WeekDay } from 'calendar-utils';
import {
  addDays,
  addMonths,
  addWeeks,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths
} from 'date-fns';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';

type CalendarPeriod = 'day' | 'week' | 'month';

function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

@Component({
  selector: 'app-calio-calendar-picker',
  templateUrl: './calio-calendar-picker.component.html',
  styleUrls: ['./calio-calendar-picker.component.scss'],
  standalone: true,
  imports: [NgStyle, LoaderComponent, NgClass, CalendarCommonModule, CalendarMonthModule, SlicePipe, DatePipe, TranslateModule]
})
export class CalioCalendarPickerComponent implements OnInit, OnDestroy {

  readonly dateIsoFormat = DATE_ISO_FORMAT;
  readonly dateTimeIsoTFormat = DATE_TIME_ISO_T_FORMAT;

  view: CalendarPeriod = 'month';

  columnHeaders: WeekDay[];
  minDate: Date = subMonths(new Date(), 0);
  maxDate: Date = addMonths(new Date(), 12);
  prevBtnDisabled: boolean = Boolean(false);
  nextBtnDisabled: boolean = Boolean(false);
  selectedMonthViewDay: WeekDay;
  selectedDayViewDate: Date;
  selectedDays: WeekDay[] = [];

  imagePath: string = environment.hostName;

  viewDate: Date = new Date();

  lastCalendarState: string;

  @Input() displayBadgeInBookingFeature = false;
  @Input() disableAllDays = false;
  @Input() selectedDate: Date;
  @Input() inactiveWeekDays: number[] = [];
  @Input() availableDays: string[] = [];
  @Input() isMultiselect = true;
  @Input() futureBookingThreshold = 0;
  @Input() disableAutomaticSearch = 1;

  @Output() selectDaysEvent: EventEmitter<{
    day: WeekDay[],
    isUserInteracted: boolean
  }> = new EventEmitter();
  @Output() monthChangedEvent = new EventEmitter<Date>();

  refreshCalendar = new Subject<any>();

  refreshCalendarBadgeInBookingSubscription: Subscription;

  @Input() selectedBookingCartItems: AppointmentCartItem[] = [];

  constructor(
    public translate: TranslateService,
    public helperService: HelperService,
    private utils: CalendarUtils,
    private customEventService: CustomEventService,
    private dateUtilService: DateUtilService,
  ) {
    const today: Date = new Date();
    const yesterday: Date = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.minDate = subMonths(yesterday, 0);

    this.customEventService.refreshCalendar.subscribe(
      () => {
        LoggerService.log('Inside refreshCalendar Subscription called');
        setTimeout(() => {
          this.disableAllDays = false;
          this.refreshCalendar.next('next');
        }, 500);
      }
    );

    this.customEventService.resetRecurringDatesEvent.subscribe(
      () => {
        for (const day of this.selectedDays) {
          this.onSelectDay(day, false);
        }
      }
    );

    this.refreshCalendarBadgeInBookingSubscription = this.customEventService.refreshCalendarBadgeInBookingEvent.subscribe(
      (result: { cartItems: AppointmentCartItem[] }) => {
        setTimeout(() => {
          // this.disableAllDays = false;
          this.refreshCalendar.next('next');
        }, 500);
      }
    );
  }

  ngOnInit(): void {
    // futureBookingThreshold
    if (this.futureBookingThreshold > 0) {
      this.maxDate = addDays(new Date(), this.futureBookingThreshold);
    }

    this.refreshHeader();
    this.disableButtons();
  }

  increment(): void {
    this.lastCalendarState = 'next';
    this.changeDate(startOfPeriod(this.view, this.viewDate));
  }

  decrement(): void {
    this.lastCalendarState = 'previous';
    this.changeDate(startOfPeriod(this.view, this.viewDate));
  }

  today(): void {
    this.changeDate(new Date());
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.disableButtons();
    if (this.viewDate < this.minDate) {
      LoggerService.warn('View date is less than min date ');
      if (this.viewDate.getMonth() === this.minDate.getMonth() && this.viewDate.getFullYear() === this.minDate.getFullYear()) {
        LoggerService.log('Month and Year of mindate and view date is same thats why loading the calendar data ');
        this.disableAllDays = true;
        this.monthChangedEvent.emit(this.viewDate);
      }
    } else if (this.viewDate > this.maxDate) {
      LoggerService.warn('View date is greater than max date ');
    } else {
      LoggerService.log('month change event called');
      this.disableAllDays = true;
      this.monthChangedEvent.emit(this.viewDate);
    }
  }

  disableButtons(): void {
    if (this.viewDate.getFullYear() === this.maxDate.getFullYear()) {
      if (this.viewDate.getMonth() >= this.maxDate.getMonth()) {
        this.nextBtnDisabled = true;
      } else {
        this.nextBtnDisabled = false;
      }
    } else if (this.viewDate.getFullYear() < this.maxDate.getFullYear()) {
      this.nextBtnDisabled = false;
    } else {
      this.nextBtnDisabled = true;
    }

    if (this.viewDate.getMonth() <= this.minDate.getMonth() && this.viewDate.getFullYear() <= this.minDate.getFullYear()) {
      this.prevBtnDisabled = true;
    } else {
      this.prevBtnDisabled = false;
    }

  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    const modifiedInactiveDays: number[] = [];
    for (const day of this.inactiveWeekDays) {
      if (day === 7) {
        modifiedInactiveDays.push(0);
      } else {
        modifiedInactiveDays.push(day);
      }
    }
    body.forEach(day => {
      if (this.disableAllDays) {
        day.cssClass = 'cal-disabled';
        return true;
      }

      // LoggerService.log('Disable is ', this.disableAllDays);

      if (!this.dateIsValid(day.date)
        || !day.inMonth
        || !this.availableDays.includes(this.dateUtilService.getFormattedDateByFormat(day.date, this.dateIsoFormat))
        || modifiedInactiveDays?.indexOf(day.day) > -1
      ) {
        day.cssClass = 'cal-disabled';
      }
      // if (this.inactiveWeekDays && this.inactiveWeekDays.indexOf(day.day)) {
      //   day.cssClass = 'cal-disabled';
      // }
      if (
        this.selectedDays.some(
          selectedDay => selectedDay.date.getTime() === day.date.getTime()
        )
      ) {
        day.cssClass = 'cal-selected';
        this.setBadgeCount(day, 'cal-selected');
      } else if (
        this.selectedDate &&
        (this.dateUtilService.getFormattedDateByFormat(this.selectedDate, this.dateIsoFormat)
          ===
          this.dateUtilService.getFormattedDateByFormat(day.date, this.dateIsoFormat))
      ) {
        // day.cssClass = 'cal-selected';
        this.onSelectDay(day, false);
      } else {
        this.setBadgeCount(day, 'cal-selected-without-highlight');
      }
    });
    if (!this.disableAllDays && this.lastCalendarState !== 'previous') {
      this.autoSearch({ body });
    }
  }

  autoSearch({ body }: { body: CalendarMonthViewDay[] }): void {
    LoggerService.log('body is ', body);
    let isAllDaysAreDisabled: boolean = Boolean(true);
    for (const day of body) {
      if (day.cssClass !== 'cal-disabled') {
        isAllDaysAreDisabled = false;
        break;
      }
    }
    if (isAllDaysAreDisabled) {
      LoggerService.log('disableAutomaticSearch ', this.disableAutomaticSearch);
      if (this.disableAutomaticSearch === 0) {

        LoggerService.log('Inside automatic search block this.viewDate ', this.viewDate, this.maxDate);
        const date: Date = addPeriod(this.view, this.viewDate, 1);

        LoggerService.log('date.getMonth() ', date.getMonth());
        LoggerService.log('this.maxDate.getMonth() ', this.maxDate.getMonth());
        LoggerService.log('date.getFullYear() ', date.getFullYear());
        LoggerService.log('this.maxDate.getFullYear() ', this.maxDate.getFullYear());
        LoggerService.log('date.getMonth() <= this.maxDate.getMonth() ', date.getMonth() <= this.maxDate.getMonth());
        LoggerService.log('date.getFullYear() <= this.maxDate.getFullYear() ', date.getFullYear() <= this.maxDate.getFullYear());
        if (date.getFullYear() === this.maxDate.getFullYear()) {
          if (date.getMonth() <= this.maxDate.getMonth()) {
            this.viewDate = date;
            this.increment();
          } else {
            LoggerService.warn('Nested ELSE Autosearch is blocked because maxDate criteria is not matching');
          }
        } else if (date.getFullYear() < this.maxDate.getFullYear()) {
          this.viewDate = date;
          this.increment();
        } else {
          LoggerService.warn('Autosearch is blocked because maxDate criteria is not matching');
        }

        /*        if (date.getMonth() <= this.maxDate.getMonth() && date.getFullYear() <= this.maxDate.getFullYear()) {
                  this.viewDate = date;
                  this.increment();
                } else {
                  LoggerService.warn('ELSE Autosearch is blocked because maxDate criteria is not matching');
                }*/
      }
    }
  }

  onSelectDay(day: WeekDay, isUserInteracted = true): void {
    this.selectedMonthViewDay = day;
    this.selectedDate = day.date;
    const selectedDateTime = this.selectedMonthViewDay.date.getTime();
    const dateIndex = this.selectedDays.findIndex(
      selectedDay => selectedDay.date.getTime() === selectedDateTime
    );
    if (dateIndex > -1) {
      delete this.selectedMonthViewDay.cssClass;
      this.selectedDays.splice(dateIndex, 1);
    } else {
      if (this.isMultiselect) {
        this.selectedDays.push(this.selectedMonthViewDay);
      } else {
        delete this.selectedMonthViewDay.cssClass;
        for (const day1 of this.selectedDays) {
          delete day1.cssClass;
        }
        this.selectedDays = [this.selectedMonthViewDay];
      }
      day.cssClass = 'cal-selected';
      this.selectedMonthViewDay = day;
    }
    this.refreshCalendar.next('next');
    this.selectDaysEvent.emit({
      day: this.selectedDays,
      isUserInteracted
    });
  }

  setBadgeCount(day: any, cssClass: string): void {
    if (this.displayBadgeInBookingFeature) {
      if (this.selectedBookingCartItems?.length > 0) {
        const foundedCardItems: AppointmentCartItem[] = this.selectedBookingCartItems.filter((item: AppointmentCartItem) => {
          return moment(item.startTime, this.dateTimeIsoTFormat)
            .format(this.dateIsoFormat) === moment(day.date).format(this.dateIsoFormat);
        });
        if (foundedCardItems?.length > 0) {
          day.cssClass = cssClass;
          day.badgeTotal = foundedCardItems?.length;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.refreshCalendarBadgeInBookingSubscription) {
      this.refreshCalendarBadgeInBookingSubscription.unsubscribe();
    }
  }

  private refreshHeader(): void {
    this.columnHeaders = this.utils.getWeekViewHeader({
      viewDate: this.viewDate,
      weekStartsOn: 1,
    });
  }

}
