import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  protected readonly restAPI = environment.restAPI;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get(path: any, options: any): Observable<any> {
    return this.httpClient.get(this.restAPI + path, options);
  }

  post(path: any, body: any, options: any): Observable<any> {
    return this.httpClient.post(this.restAPI + path, body, options);
  }

  put(path: any, body: any, options: any): Observable<any> {
    return this.httpClient.put(this.restAPI + path, body, options);
  }

  delete(path: any, options: any): Observable<any> {
    return this.httpClient.delete(this.restAPI + path, options);
  }
}
