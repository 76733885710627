<div
  class="card {{ customCardClass }}"
  [ngStyle]="cardStylesConfig"
  [ngClass]="{
    'shadow': showShadow,
    'disabled-card-box': isDisabled,
    'cursor-pointer': showCursorPointer,
    'selected-card': isSelected
  }"
  (click)="onCardClick()"
>
  @if (showHeader) {
    <div class="card-header">
      <ng-content select="[cardHeader]" />
    </div>
  }
  @if (showImage) {
    <div class="cardImage">
      <ng-content select="[cardImage]" />
    </div>
  }
  @if (showBody) {
    <div class="card-body">
      <ng-content select="[cardBody]" />
    </div>
  }
  @if (showFooter) {
    <div class="card-footer">
      <ng-content select="[cardFooter]" />
    </div>
  }
</div>
