import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-csw-text',
  templateUrl: './csw-text.component.html',
  styleUrls: ['./csw-text.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class CswTextComponent {
  @Input() text: any;
  @Input() styleConfig: {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
  } = {};
  @Input() customClass: string;
}
