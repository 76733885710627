import {Injectable} from '@angular/core';
import {CryptoService} from './crypto.service';
import {environment} from '../../../environments/environment';
import {LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor(
    private cryptoService: CryptoService,
  ) {
  }

  setItem(key: string, value: string): void {
    const encryptedString = this.cryptoService.encryptValue(value);
    try {
      localStorage.setItem(key + '_' + environment.version, encryptedString);
    } catch (error) {
      LoggerService.warn(error);
    }
  }

  getItem(key: string): string {
    try {
      const encryptedPartner: string = localStorage.getItem(
        key + '_' + environment.version
      );
      if (encryptedPartner) {
        return this.cryptoService.decryptValue(encryptedPartner);
      }
    } catch (error) {
      LoggerService.warn(error);
    }
    return null;
  }

  removeItem(key: string): void {
    try {
      localStorage.removeItem(key + '_' + environment.version);
    } catch (error) {
      LoggerService.warn(error);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      LoggerService.warn(error);
    }
  }
}
