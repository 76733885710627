import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { BOTTOM_TYPES, TRIGGER_TYPE } from '@constants/app.constants';
import { CustomEventService } from '@services/feature/custom-event.service';
import { WidgetService } from '@services/feature/widget.service';
import { LoggerService } from '@services/utils/logger.service';
import { SmartwidgetEventsService } from '@services/utils/smartwidget-events.service';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'app-widget-layout',
    templateUrl: './widget-layout.component.html',
    styleUrls: ['./widget-layout.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass, TranslateModule, CalioSafePipe]
})
export class WidgetLayoutComponent implements OnInit {

  @Input() showMainWidgetBody = true;

  readonly workerAvatarBaseUrl = environment.workerAvatarBaseUrl;

  showFillContainer = false;
  title = 'Calenso Smart Widget';
  description: string;
  showMainWidget = false;
  showTeaserWidget = false;
  headerConfig: {
    background: string,
    color: string
  } = {
    background: '#7676FF',
    color: 'white'
  };
  triggerIconConfig: {
    background: string,
    color: string,
    right: string,
    left?: string,
    bottom?: string,
  } = {
    background: '#7676FF',
    color: 'white',
    right: '20px',
    bottom: '20px',
  };
  teaserConfig: {
    bottomConstant: number,
    styleConfig: {
      right: string,
      left?: string,
      bottom?: string,
    }
  } = {
    bottomConstant: 86,
    styleConfig: {
      right: '20px',
      bottom: '86px',
    }
  };
  mainWidgetContainerConfig: {
    bottomConstant: number,
    styleConfig: { bottom: string }
  } = {
    bottomConstant: 86,
    styleConfig: {
      bottom: '86px'
    }
  };
  errorConfig: {
    showError: boolean,
    message: string,
  } = {
    showError: false,
    message: undefined
  };
  triggerData: {
    message: string,
    isPredefined: boolean,
  };

  constructor(
    public widgetService: WidgetService,
    private smartwidgetEventsService: SmartwidgetEventsService,
    private customEventService: CustomEventService,
  ) {
    if (this.widgetService.widgetConf) {
      this.setupWidget();
    }
  }

  ngOnInit(): void {
    this.smartwidgetEventsService.triggerTeaserEvent.subscribe(
      (result: { message: string, isPredefined: boolean }) => {
        this.triggerData = result;
        this.showTeaserWidget = true;
      }
    );

    this.customEventService.toggleFillContainer.subscribe(result => this.showFillContainer = result);
  }

  toggleWidget(): void {
    LoggerService.log('Inside toggleWidget()');
  }

  toggleMainWidget(): void {
    this.showMainWidget = !this.showMainWidget;
    this.showTeaserWidget = false;
  }

  resetMainWidget(): void {
    this.showMainWidget = false;
    this.showTeaserWidget = false;
    this.customEventService.resetMainWidgetEvent.emit();
  }

  toggleTeaserWidget(): void {
    this.showTeaserWidget = !this.showTeaserWidget;
  }

  setupWidget(): void {
    if (this.isValidWhiteListedDomain()) {
      this.setupHeaderConfig();
      this.setupToggleButtonConfig();
      this.setupToggleTitle();
      this.setupTeasureWidget();
      this.setupMainWidgetContainer();
    } else {
      // TODO handle invoid case
      this.setupHeaderConfig();
      this.setupToggleButtonConfig();
      this.setupToggleTitle();
      this.setupTeasureWidget();
      this.setupMainWidgetContainer();
    }
  }

  isValidWhiteListedDomain(): boolean {
    if (this.widgetService?.widgetConf?.whitelisted_domains?.length > 0) {
      if (this.widgetService?.widgetConf?.whitelisted_domains.indexOf(this.widgetService.requestedClientDomain) > -1) {
        return true;
      } else {
        LoggerService.warn(`SMART_WIDGET:WARN ${this.widgetService.requestedClientDomain} requested domain is not whitelisted.`);
        this.errorConfig = {
          showError: true,
          message: 'Your domain is not whitelisted'
        };
        return false;
      }
    }
    return true;
  }

  setupHeaderConfig(): void {
    if (this.widgetService.widgetConf.header_background_color) {
      this.headerConfig.background = this.widgetService.widgetConf.header_background_color;
    }
    if (this.widgetService.widgetConf.header_font_color) {
      this.headerConfig.color = this.widgetService.widgetConf.header_font_color;
    }
  }

  setupToggleButtonConfig(): void {
    if (this.widgetService.widgetConf.button_background_color) {
      this.triggerIconConfig.background = this.widgetService.widgetConf.button_background_color;
    }
    if (this.widgetService.widgetConf.button_font_color) {
      this.triggerIconConfig.color = this.widgetService.widgetConf.button_font_color;
    }
    LoggerService.log('this.widgetService.widgetConf.position ', this.widgetService.widgetConf.position);
    LoggerService.log('this.widgetService.widgetConf.bottom_padding ', this.widgetService.widgetConf.bottom_padding);
    if (this.widgetService.widgetConf.position) {
      if (this.widgetService.widgetConf.position === BOTTOM_TYPES.BOTTOM_LEFT) {
        this.triggerIconConfig.left = this.widgetService.widgetConf.left_padding ? this.widgetService.widgetConf.left_padding + 'px' : '20px';
        this.triggerIconConfig.right = 'unset';
      } else if (this.widgetService.widgetConf.position === BOTTOM_TYPES.BOTTOM_RIGHT) {
        this.triggerIconConfig.right = this.widgetService.widgetConf.right_padding ? this.widgetService.widgetConf.right_padding + 'px' : '20px';
        this.triggerIconConfig.left = 'unset';
      }
      this.triggerIconConfig.bottom = this.widgetService.widgetConf.bottom_padding ? this.widgetService.widgetConf.bottom_padding + 'px' : '20px';
      this.triggerIconConfig.color = this.widgetService.widgetConf.button_font_color;
    }
  }

  setupToggleTitle(): void {
    if (this.widgetService.widgetConf.title) {
      this.title = this.widgetService.widgetConf.title;
    }
    if (this.widgetService.widgetConf.description) {
      this.description = this.widgetService.widgetConf.description;
    }
  }

  setupTeasureWidget(): void {
    if (this.widgetService.widgetConf.position) {
      if (this.widgetService.widgetConf.position === BOTTOM_TYPES.BOTTOM_LEFT) {
        this.teaserConfig.styleConfig.left
          = this.widgetService.widgetConf.left_padding ? this.widgetService.widgetConf.left_padding + 'px' : '20px';
        this.teaserConfig.styleConfig.right = 'unset';
      } else if (this.widgetService.widgetConf.position === BOTTOM_TYPES.BOTTOM_RIGHT) {
        this.teaserConfig.styleConfig.right = this.widgetService.widgetConf.right_padding ? this.widgetService.widgetConf.right_padding + 'px' : '20px';
        this.teaserConfig.styleConfig.left = 'unset';
      }
    }
    if (this.widgetService.widgetConf.bottom_padding > 0) {
      this.teaserConfig.styleConfig.bottom = (this.teaserConfig.bottomConstant - 20 + this.widgetService.widgetConf.bottom_padding) + 'px';
    }
  }

  setupMainWidgetContainer(): void {
    if (this.widgetService.widgetConf.bottom_padding > 0) {
      this.mainWidgetContainerConfig.styleConfig.bottom
        = (this.mainWidgetContainerConfig.bottomConstant - 20 + this.widgetService.widgetConf.bottom_padding) + 'px';
    }
  }

  setupWidgetTriggerType(): void {
    if (this.widgetService.widgetConf.trigger === TRIGGER_TYPE.IMMEDIATELY) {
      this.toggleMainWidget();
    }
  }
}
