@if (widgetService.partnerUUID) {
  <link
    rel="stylesheet"
    type="text/css"
    [href]="'/assets/css/survey/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css' | calioSafe : 'resourceUrl'"
  />
} 
@if (showSkeleton) {
  <div>
    <div class="skeleton-box-card">
      <div>
        @for (ghostElement of [1,2,3,4, 5, 6]; track ghostElement) {
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="d-flex stats-card">
                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column">
                  <div>
                    <div class="stat-box"></div>
                    <div class="stat-box-1 mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
            @for (ghostElement of [1,2,3,4]; track ghostElement) {
            <div class="col-md-3">
              <div class="d-flex stats-card shadow">
                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                  <div>
                    <div class="stat-box"></div>
                    <div class="stat-box-1 mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
}
<div class="survey-app">
  <mat-horizontal-stepper
    #stepper
    [disableRipple]="true"
    class="pt-4"
    [ngStyle]="{ 'd-none': showSkeleton, 'd-block': !showSkeleton }"
    [linear]="showCustomForm"
    (selectionChange)="onStepChanged($event)"
  >
    @for (surveyStep of surveyService?.surveySteps; track surveyStep; let last = $last; let index = $index) {
      <form #stepperForm="ngForm">
        <mat-step [completed]="!showCustomForm">
          <ng-template matStepLabel>
            {{ surveyStep | translation : "title" : lang }}
          </ng-template>
          <div class="mt-4">
            <div>
              <app-csw-text
                [text]="surveyStep.is_start === 1 ? (surveyStep | translation : 'title' : lang) : surveyStep.step_number + ' - ' + (surveyStep | translation : 'title' : lang)"
                [styleConfig]="{ fontWeight: 600, fontSize: '28px' }"
              />
            </div>
            <div class="mt-3">
              <app-csw-text
                [text]="surveyStep | translation : 'description' : lang"
                [styleConfig]="{}"
              />
            </div>
          </div>
          <div class="mt-5">
            @if (showErrorMessage) {
              <div class="alert alert-danger">
                {{ errorMessage | translate }}
              </div>
            } 
            @if (surveyStep.is_start === 1 && (widgetService?.widgetConf | translation : 'start_text' : lang)?.trim()) {
              <app-csw-text
                [text]="widgetService?.widgetConf | translation : 'start_text' : lang | calioSafe : 'html'"
                [styleConfig]="{}"
              />

              @if (showCustomForm) {
                <div class="mt-3">
                  <app-survey-customer-form />
                </div>
              } 
            } 
            @if (surveyStep.is_end === 1) {
              <app-survey-end-step />
            } 
            @if (surveyStep.is_start !== 1 && surveyStep.is_end !== 1) { 
              @if (showStepperContent) { 
                @if (surveyStep.is_appointment_booking_step === 0) {
                  <app-survey-questions-view [currentSurveyStep]="surveyStep" />
                } 
                @if (surveyStep.is_appointment_booking_step === 1) { 
                  @if (showFinalStep) {
                    <app-survey-final-step
                      (endProcessEvent)="onEndProcessEvent(); goForward(stepper, surveyStep)"
                      (bookAppointmentEvent)="onBookAppointmentEvent()"
                    />
                  }
                  <div [ngStyle]="{ display: showBookingWidget ? 'block' : 'none' }">
                    <app-survey-integrate-booking-widget />
                  </div>
                } 
              } 
            }
          </div>
          <div class="cbw-survey-buttons">
            @if (surveyStep.is_start !== 1) {
              <div class="pe-2">
                @if (!last) {
                  <button
                    type="button"
                    class="btn btn-primary"
                    [ngStyle]="{
                      background: widgetService?.widgetConf?.button_background_color,
                      color: widgetService?.widgetConf?.button_font_color,
                      borderColor: widgetService?.widgetConf?.button_background_color,
                      borderRadius: '0px'
                    }"
                    (click)="goBackward(stepper)"
                  >
                    <div class="d-flex align-items-center">
                      @if (surveyStep.is_start !== 1) {
                        <div class="pe-3">
                          <mat-icon class="button-icon d-flex" svgIcon="chevron-left-solid" />
                        </div>
                      }
                      <div>
                        @if (surveyStep.is_start !== 1) {
                          <app-csw-text 
                            [text]="('form_fields.previous' | translate) + ': ' + (surveyService?.surveySteps[index - 1] | translation : 'title' : lang)"
                            [styleConfig]="{ fontSize: '14px' }"
                          />
                        }
                      </div>
                    </div>
                  </button>
                }
              </div>
            }
            <div>
              @if (!last) {
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="handleNextButtonState(surveyStep, stepperForm.invalid)"
                  [ngStyle]="{
                    background: widgetService?.widgetConf?.button_background_color,
                    color: widgetService?.widgetConf?.button_font_color,
                    borderColor: widgetService?.widgetConf?.button_background_color,
                    borderRadius: '0px'
                  }"
                  (click)="goForward(stepper, surveyStep)"
                >
                  <div class="d-flex align-items-center">
                    <div>
                      @if (surveyStep.is_start === 1) {
                        <app-csw-text
                          [text]="'form_fields.start' | translate"
                          [styleConfig]="{ fontSize: '14px' }"
                        />
                      } 
                      @if (surveyStep.is_start !== 1) {
                        <app-csw-text
                          [text]="('form_fields.next' | translate) + ': ' + (surveyService?.surveySteps[index + 1] | translation : 'title' : lang)"
                          [styleConfig]="{ fontSize: '14px' }"
                        />
                      }
                    </div>
                    @if (surveyStep.is_start !== 1) {
                      <div class="ps-3">
                        <mat-icon class="button-icon d-flex" svgIcon="chevron-right-solid" />
                      </div>
                    }
                  </div>
                </button>
              }
            </div>
          </div>
        </mat-step>
      </form>
    }
  </mat-horizontal-stepper>
</div>
@if (surveyService?.partnerData?.analytics?.GTM && surveyService?.partnerData?.analytics?.GTM.trim() !== '') {
  <div>
    <app-gtm-operation [gtmID]="surveyService?.partnerData?.analytics?.GTM" />
  </div>
}
