
export class CssOverwrites {
  id: number;
  uuid: string;
  name: string;
  code: string;
  type: string;
  is_active: number;
  partner_id: number;
  smart_widget_configuration_id: number;
  errors?: any;
}

export enum CUSTOM_CSS_WIDGET_TYPE {
  widget = 'widget',
  smart_widget = 'smart_widget',
  compact_widget = 'compact_widget'
}
