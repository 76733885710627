import { Injectable } from '@angular/core';
import { HttpClientService } from '../utils/http-client.service';
import { Observable, of } from 'rxjs';
import { CustomFieldModel } from '../../models/custom-field.model';
import { switchMap } from 'rxjs/operators';
import { CustomCustomFieldFileDbModel, CustomerCustomFieldDbModel } from '../../models/customer.model';
import { LoggerService } from '../utils/logger.service';
import { CUSTOM_FIELD_TYPES } from '../../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  private _imageUploaded = true;
  get imageUploaded(): boolean { return this._imageUploaded; }
  set imageUploaded(state: boolean) { this._imageUploaded = state; }

  constructor(private httpClientService: HttpClientService) {
  }

  public getFilesByFileIds(fileIds: number[]): Observable<CustomCustomFieldFileDbModel[]> {
    const body: { ids: number[] } = { ids: fileIds };
    return this.httpClientService.post('files/get_files_by_ids', body, {});
  }

  public getCustomFields(): Observable<CustomFieldModel[]> {
    return this.httpClientService.get(`custom_fields`, {}).pipe(
      (switchMap((customFields: CustomFieldModel[]) => {
        if (customFields && customFields.length > 0) {
          for (const customField of customFields) {
            customField.position = Number(customField.position);
          }
        }
        return of(customFields);
      }))
    );
  }

  public getCustomFieldsByIds(ids: number[]): Observable<CustomFieldModel[]> {
    const body: { ids: number[] } = { ids };

    return this.httpClientService.post(`custom_fields/get_by_ids`, body, {}).pipe(
      (switchMap((customFields: CustomFieldModel[]) => {
        if (customFields && customFields.length > 0) {
          for (const customField of customFields) {
            customField.position = Number(customField.position);
          }
        }
        return of(customFields);
      }))
    );
  }


  getAppointmentCustomFields(servicesIds: number[]): Observable<CustomFieldModel[]> {
    const body: { appointment_service_ids: number[] } = {
      appointment_service_ids: servicesIds
    };
    return this.httpClientService
      .post('custom_fields/custom_fields_by_appointment_service_ids', body, {});
  }

  public uploadCustomerCustomFieldFiles(
    fileList: FileList, customField: CustomFieldModel, customerId: number
  ): Observable<CustomerCustomFieldDbModel[]> {
    const formData: FormData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('file[]', fileList[i]);
    }
    formData.append('customer_id', customerId + '');
    formData.append('custom_field_id', customField.id + '');
    return this.httpClientService.post(`files/add_file_for_custom_field`, formData, {});
  }

  public uploadCustomFieldFiles(
    fileList: FileList, customField: CustomFieldModel
  ): Observable<CustomCustomFieldFileDbModel[]> {
    const formData: FormData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('file[]', fileList[i]);
    }
    if (customField) {
      formData.append('custom_field_id', customField.id + '');
    }

    return this.httpClientService.post(`files`, formData, {});
  }

  public deleteCustomerCustomFieldFile(customerCustomFieldUuid: string):
    Observable<{ success: boolean }> {
    return this.httpClientService.delete(`customer_custom_fields/${customerCustomFieldUuid}`, {});
  }

  public deleteFile(fileUuid: string, customFieldId: number): Observable<{ success: boolean }> {
    return this.httpClientService.delete(`files/${fileUuid}`, {}).pipe(
      (switchMap((result: { success: boolean }) => {
        LoggerService.log('remove file from custom field local storage');
        return of(result);
      }))
    );
  }

  prefillCustomFieldSelectedValue(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    if (widgetQuestion.is_read_only === 0) {
      switch (widgetQuestion.type) {
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT:
          this.prefillSelectCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_CHECKBOX:
          this.prefillCheckboxCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE:
          this.prefillFileCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO:
          this.prefillRadioCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_DATE:
          this.prefillDateCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_NUMBER_SELECT_CARD:
          this.prefillNumberSelectCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD:
          this.prefillImageSelectCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD:
          this.prefillSelectCardCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TEXT:
          this.prefillTextCustomField(widgetQuestion, filteredCustomFields);
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TEXT_AREA:
          this.prefillTextAreaCustomField(widgetQuestion, filteredCustomFields);
          return;
      }
    } else {
      LoggerService.warn('Prefill prevented as question is marked as readonly');
    }
  }

  prefillSelectCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    for (const customField of filteredCustomFields) {
      const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
      if (!widgetQuestion.customerSelectedValue[indexKey]) {
        widgetQuestion.customerSelectedValue[indexKey] = [];
      }

      if (customField.custom_field && customField.custom_field.is_multiple_select === 1) {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey].push(Number(customField.value));
        }
      } else {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey] = Number(customField.value);
        }
      }
    }
  }

  prefillCheckboxCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];
    if (filteredCustomFields.length > 0) {
      for (const customField of filteredCustomFields) {
        const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
        if (!widgetQuestion.customerSelectedValue[indexKey]) {
          widgetQuestion.customerSelectedValue[indexKey] = [];
        }

        widgetQuestion.customerSelectedValue[indexKey] = customField.value === 'true' ? true : false;
      }
    } else {
      widgetQuestion.customerSelectedValue = [false];
    }
  }

  prefillFileCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    LoggerService.log('widgetQuestion ', widgetQuestion);
    LoggerService.log('filteredCustomFields ', filteredCustomFields);
    if (filteredCustomFields?.length > 0) {
      for (const fcf of filteredCustomFields) {
        if (fcf?.file?.id) {
          const indexKey = (fcf?.belongs_to_column ? (fcf.belongs_to_column - 1) : 0);
          if (!widgetQuestion.customerSelectedValue[indexKey]) {
            widgetQuestion.customerSelectedValue[indexKey] = [];
          }

          widgetQuestion.customerSelectedValue[indexKey].push(fcf.file.id);
          widgetQuestion.customerSelectedFileValue[indexKey].push({
            file: fcf.file,
            customerCustomFieldUuids: fcf.uuid,
            selected: false,
          });
        }
      }
    }
  }

  prefillRadioCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    for (const customField of filteredCustomFields) {
      const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
      if (!widgetQuestion.customerSelectedValue[indexKey]) {
        widgetQuestion.customerSelectedValue[indexKey] = [];
      }

      if (customField.custom_field && customField.custom_field.is_multiple_select === 1) {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey].push(Number(customField.value));
        }
      } else {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey] = Number(customField.value);
        }
      }
    }
  }

  prefillDateCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    if (filteredCustomFields.length > 0) {
      for (const customField of filteredCustomFields) {
        const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
        if (!widgetQuestion.customerSelectedValue[indexKey]) {
          widgetQuestion.customerSelectedValue[indexKey] = [];
        }

        widgetQuestion.customerSelectedValue[indexKey] = customField.value;
      }
    }
  }

  prefillNumberSelectCustomField(widgetQuestion: CustomFieldModel, filteredCustomFields: CustomerCustomFieldDbModel[]): void {
    LoggerService.log('Inside prefillNumberSelectCustomField');
    LoggerService.log('Inside prefillNumberSelectCustomField widgetQuestion ', widgetQuestion);
    LoggerService.log('Inside prefillNumberSelectCustomField filteredCustomFields ', filteredCustomFields);


    if (!widgetQuestion?.customerSelectedValue) {
      widgetQuestion.customerSelectedValue = [];
    }

    for (const option of widgetQuestion.custom_field_options) {
      const foundOptions: CustomerCustomFieldDbModel[] = filteredCustomFields.filter((item: CustomerCustomFieldDbModel) => {
        return option.id === Number(item.value);
      });
      LoggerService.log('foundOptions ', foundOptions);
      if (foundOptions) {
        for (const foundOption of foundOptions) {
          const indexKey = (foundOption?.belongs_to_column ? (foundOption.belongs_to_column - 1) : 0);
          if (!widgetQuestion.customerSelectedValue[indexKey]) {
            widgetQuestion.customerSelectedValue[indexKey] = [];
          }
          option.option_value = Number(foundOption.option_value);
          widgetQuestion.customerSelectedValue[indexKey].push({
            custom_field_option_id: option.id,
            custom_field_option_value: Number(foundOption.option_value)
          });
        }
      } else {
        option.option_value = option.number_default;
      }
    }
  }

  prefillImageSelectCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    for (const customField of filteredCustomFields) {
      const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
      if (!widgetQuestion.customerSelectedValue[indexKey]) {
        widgetQuestion.customerSelectedValue[indexKey] = [];
      }

      if (customField.custom_field && customField.custom_field.is_multiple_select === 1) {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey].push(Number(customField.value));
        }
      } else {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey] = [Number(customField.value)];
        }
      }
    }

    LoggerService.log('widgetQuestion.customerSelectedValue ', widgetQuestion.customerSelectedValue);
  }

  prefillSelectCardCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    for (const customField of filteredCustomFields) {
      const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
      if (!widgetQuestion.customerSelectedValue[indexKey]) {
        widgetQuestion.customerSelectedValue[indexKey] = [];
      }

      if (customField.custom_field && customField.custom_field.is_multiple_select === 1) {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey].push(Number(customField.value));
        }
      } else {
        if (customField.value && customField.value.trim() !== '') {
          widgetQuestion.customerSelectedValue[indexKey] = [Number(customField.value)];
        }
      }
    }
  }

  prefillTextCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    if (filteredCustomFields.length > 0) {
      for (const customField of filteredCustomFields) {
        const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
        if (!widgetQuestion.customerSelectedValue[indexKey]) {
          widgetQuestion.customerSelectedValue[indexKey] = [];
        }

        widgetQuestion.customerSelectedValue[indexKey] = customField.value;
      }
    }
  }

  prefillTextAreaCustomField(
    widgetQuestion: CustomFieldModel,
    filteredCustomFields: CustomerCustomFieldDbModel[]
  ): void {
    widgetQuestion.customerSelectedValue = [];

    if (filteredCustomFields.length > 0) {
      for (const customField of filteredCustomFields) {
        const indexKey = (customField?.belongs_to_column ? (customField.belongs_to_column - 1) : 0);
        if (!widgetQuestion.customerSelectedValue[indexKey]) {
          widgetQuestion.customerSelectedValue[indexKey] = [];
        }

        widgetQuestion.customerSelectedValue[indexKey] = customField.value;
      }
    }
  }
}
