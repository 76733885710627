import {WINDOW_LISTENER_CONSTANTS} from './../../constants/window-listener.constants';
import {WidgetService} from './../feature/widget.service';
import {Injectable} from '@angular/core';
import {LoggerService} from './logger.service';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private widgetService: WidgetService) {
  }

  public emitAppointmentBookingEvent(
    eventName: string,
    params: {[key: string]: any} = null
  ): void {
    LoggerService.log('Appointment booking step event ', eventName);
    const type = 'TRACKER_TRIGGER_VIEW_END';
    this.sendAppointmentBookingStepsToParentWebsite(eventName);

    // Google Tag Manager
    try {
      dataLayer.push({
        event: eventName,
        ...params
      });
      LoggerService.log(`[GOOGLE_TAG]:[${eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Datalayer not defined error is handled');
    }

    // Adobe launch tracking
    try {
      const someClick = new CustomEvent(type, {
        detail: {
          name: eventName,
          params
        }
      });
      window.dispatchEvent(someClick);
      LoggerService.log(`[ADOBE_LAUNCH_TRACKING]:[${eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Adobe event error handler');
    }
  }

  public sendAppointmentBookingStepsToParentWebsite(eventName: string): void {
    if (this.widgetService?.parentToChildOriginEvent) {
      this.widgetService.sendMessageToMainJs(
        this.widgetService.parentToChildOriginEvent,
        {
          event: WINDOW_LISTENER_CONSTANTS.CALENSO_CHILD_TO_PARENT_EVENT,
          note: 'Child To Parent Communication Done',
          step: eventName,
        }
      );
    }
  }

  public emitSurveyEvent(data: {
    eventName: string,
    eventStep: number,
    stepName: string,
  }): void {
    // Google Tag Manager
    try {
      dataLayer.push({
        event: data.eventName,
        step: data.eventStep,
        stepName: data.stepName
      });
      LoggerService.log(`[GOOGLE_TAG]:[${data.eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Datalayer not defined error is handled');
    }

    // Adobe launch tracking
    try {
      const type = 'TRACKER_TRIGGER_VIEW_END';
      const someClick = new CustomEvent(type, {
        detail: {
          name: data.eventName
        }
      });
      window.dispatchEvent(someClick);
      LoggerService.log(`[ADOBE_LAUNCH_TRACKING]:[${data.eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Adobe event error handler');
    }
  }
}
