<div class="csw-main-widget">
  <!-- Widget trigger button starts -->
  <div class="csw-trigger-button" [ngStyle]="triggerIconConfig">
    <div id="csw-trigger-button-overlay"></div>
    @if (!showMainWidget) {
      <div class="csw-trigger-button-open-icon" (click)="toggleMainWidget()">
        <img src="/assets/images/icons/smartwidget_logo.svg">
      </div>
    }
    @if (showMainWidget) {
      <div class="csw-trigger-button-close-icon" (click)="resetMainWidget()">
        <img src="/assets/images/icons/smartwidget_close.svg">
      </div>
    }
  </div>
  <!-- Widget trigger button ends -->
  <!-- Main Widget Popup starts -->
  @if (showMainWidget) {
    <div class="csw-main-widget-container shadow" [ngStyle]="mainWidgetContainerConfig?.styleConfig">
      <div class="csw-main-widget-header d-flex justify-content-between align-items-center px-3" [ngStyle]="headerConfig">
        <div class="d-flex align-items-center">
          @if (widgetService?.widgetWorkerData?.avatar && widgetService?.widgetConf?.context?.show_worker_avatar) {
            <div class="pe-2">
              @if (widgetService?.widgetWorkerData.is_resource === 0) {
                <img
                  src="{{workerAvatarBaseUrl}}{{widgetService?.widgetWorkerData?.uuid}}/thumb_{{widgetService?.widgetWorkerData?.avatar}}"
                  class="rounded-circle" alt=""
                  width="36" height="36" onError="this.src='/assets/images/icons/default_photo_ico.svg';"
                />
              }
            </div>
          }
          <div>
            <div class="title">{{ title }}</div>
            <div class="description">{{ description }}</div>
          </div>
        </div>
        <div (click)="resetMainWidget()" class="csw-widget-close-button">
          <img src="/assets/images/icons/smartwidget_close.svg">
        </div>
      </div>
      <div class="csw-main-widget-body">
        <div class="csw-main-widget-body-container" [ngClass]="{
          'csw-main-widget-body-fill-container': showFillContainer,
          'csw-main-widget-body-container': !showFillContainer
          }"
        >
          @if (errorConfig.showError) {
            <div class="alert alert-danger">{{ errorConfig.message }}</div>
          }
          @if (showMainWidgetBody) {
            <div>
              <ng-content select="[mainWidgetBody]"></ng-content>
            </div>
          }
        </div>
      </div>
      <div class="csw-main-widget-footer d-flex justify-content-center align-items-center pb-2">
        <div class="footer-logo">
          <img class="footer-logo" src="/assets/images/logo/calenso_logo_new.svg">
        </div>
      </div>
    </div>
  }
  <!-- Main Widget Popup ends -->
  <!-- teaser widget starts -->
  @if (showTeaserWidget) {
    <div class="csw-teaser-widget-container shadow p-3" [ngStyle]="teaserConfig?.styleConfig">
      <div class="csw-teaser-close-button-container">
        <span (click)="toggleTeaserWidget()"
          class="csw-teaser-close-button d-flex justify-content-center align-items-center">
          <img src="/assets/images/icons/smartwidget_close.svg">
        </span>
      </div>
      <div class="csw-teaser-widget-body" (click)="toggleTeaserWidget();toggleMainWidget();">
        <div class="d-flex align-items-start">
          @if (widgetService?.widgetWorkerData?.avatar && widgetService?.widgetConf?.context?.show_worker_avatar) {
            <div class="pe-2">
              @if (widgetService?.widgetWorkerData.is_resource === 0) {
                <img
                  src="{{workerAvatarBaseUrl}}{{widgetService?.widgetWorkerData?.uuid}}/thumb_{{widgetService?.widgetWorkerData?.avatar}}"
                  class="rounded-circle" alt=""
                  width="36" height="36" onError="this.src='/assets/images/icons/default_photo_ico.svg';"
                />
              }
            </div>
          }
          <div>
            @if (triggerData?.isPredefined) {
              <span [innerHTML]="('triggerData?.message' | translate) | calioSafe: 'html'">
              </span>
            }
            @if (!triggerData?.isPredefined) {
              <!-- TODO we need to load the translation from translation pipe -->
              <span [innerHTML]="triggerData?.message | calioSafe: 'html'">
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  }
  <!-- teaser widget ends -->
</div>
