import { Component, Input, OnInit } from '@angular/core';
import { PayPalOrderDetailModel, PayPalSettingsDbModel } from '@models/partner-setting.model';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { LoggerService } from '@services/utils/logger.service';
import { ICreateOrderRequest, IPayPalConfig, NgxPayPalModule } from 'ngx-paypal';

@Component({
    selector: 'app-cw-paypal',
    templateUrl: './cw-paypal.component.html',
    styleUrls: ['./cw-paypal.component.scss'],
    standalone: true,
    imports: [NgxPayPalModule]
})
export class CwPaypalComponent implements OnInit {

  @Input() paypalSetting: PayPalSettingsDbModel;
  @Input() price: number;
  @Input() discount: number;
  // @Input() partner: Partner;
  // @Input() cart: CartItem[];
  // @Input() appoinrmentServicesAvailable: any;
  // @Input() eventCart: EventCartItem[];
  // @Input() widgetType: string;
  // @Input() additionalGuests: GuestModel[] = [];

  public payPalConfig ?: IPayPalConfig;

  showSuccess: boolean = Boolean(false);
  showCancel: boolean = Boolean(false);
  showError: boolean = Boolean(false);

  constructor(
    private customEventService: CustomEventService,
    private bookingService: BookingService,
  ) {
  }

  ngOnInit(): void {
    // LoggerService.log('Inside ngOnInit this.cart ', this.cart);
    /*this.customEventService.resetPayPalOrderDetailEvent.subscribe(
      (result: any) => {
        LoggerService.log('Reset paypal order');
        this.discount = undefined;
        this.payPalConfig = undefined;
        setTimeout(() => {
          this.initConfig();
        }, 300);
      }
    );*/

    /* this.customEventService.cartItemsChangeEvent.subscribe(
       (result: {
         cart?: CartItem[],
         eventCart?: EventCartItem[],
         type: string,
       }) => {
         LoggerService.log('Inside cartItemsChangeEvent result ', result);
         this.discount = undefined;
         this.payPalConfig = undefined;
         if (result.type === WIDGET_CONSTANTS.APPOINTMENT) {
           this.cart = result.cart;
         } else {
           this.eventCart = result.eventCart;
         }

         setTimeout(() => {
           this.initConfig();
         }, 500);
       }
     );*/

    /*this.customEventService.couponChangeEvent.subscribe(
      (couponDiscount: number) => {
        LoggerService.log('Inside couponChangeEvent result ', couponDiscount);
        if (this.widgetType === WIDGET_CONSTANTS.APPOINTMENT) {
          this.discount = couponDiscount;
          this.payPalConfig = undefined;
          setTimeout(() => {
            this.initConfig();
          }, 1000);
        }
      }
    );*/

    /*
      this.customEventService.additionalGuestChangeEvent.subscribe(
        (result: {
          finalPrice: number,
          discount: number,
          additionalGuests: GuestModel[]
        }) => {
          LoggerService.log('Inside this.customEventService.additionalGuestChangeEvent result ', result);
          if (this.discount === result.discount && this.price === result.finalPrice && this.additionalGuests?.length === result.additionalGuests?.length) {
            LoggerService.log('Inside if all values are same. No change.');
          } else {
            LoggerService.log('Inside else some changes in the values.');
            this.payPalConfig = undefined;
            this.discount = result.discount;
            this.price = result.finalPrice;
            this.additionalGuests = result.additionalGuests;
            setTimeout(() => {
              this.initConfig();
            }, 200);
          }
        }
      );
    */

    setTimeout(() => {
      this.initConfig();
    }, 400);
  }

  private initConfig(): void {
    let totalPrice: number = Number(this.price);
    if (this.discount > 0) {
      totalPrice = totalPrice + this.discount;
    }

    const finalItems: any[] = this.getAppointmentPaypalItems();

    this.payPalConfig = {
      currency: this.bookingService.partnerData.currency,
      clientId: this.paypalSetting.client_id,
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.bookingService.partnerData.currency,
            value: this.price + '',
            breakdown: {
              item_total: {
                currency_code: this.bookingService.partnerData.currency,
                value: totalPrice + '',
              },
              discount: {
                currency_code: this.bookingService.partnerData.currency,
                value: (this.discount > 0 ? this.discount + '' : '0')
              }
            }
          },
          items: finalItems
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        color: 'gold',
        shape: 'rect',
      },
      onApprove: (data, actions) => {
        LoggerService.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          LoggerService.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data: any) => {
        LoggerService.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
        const newPayPalOrderDetailModel: PayPalOrderDetailModel = {
          paypal_order_id: data.id,
          paypal_payer_id: data.payer ? data.payer.payer_id : null,
          paypal_status: data.status,
          paypal_raw_data: JSON.stringify(data),
          paypal_capture_id: undefined,
        };

        if (data?.purchase_units && data?.purchase_units[0]) {
          if (data?.purchase_units[0]?.payments
            && data?.purchase_units[0]?.payments?.captures
            && data?.purchase_units[0]?.payments?.captures[0]) {
            newPayPalOrderDetailModel.paypal_capture_id = data?.purchase_units[0]?.payments?.captures[0].id;
          }
        }

        LoggerService.log('newPayPalOrderDetailModel ', newPayPalOrderDetailModel);

        this.customEventService.paypalSuccessfulEvent.emit(
          newPayPalOrderDetailModel
        );
      },
      onCancel: (data, actions) => {
        LoggerService.log('OnCancel', data, actions);
        this.showCancel = true;

      },
      onError: err => {
        LoggerService.log('OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        LoggerService.log('onClick', data, actions);
        this.resetStatus();
      }
    };
  }

  resetStatus(): void {
    this.showError = false;
    this.showSuccess = false;
    this.showCancel = false;
  }

  onPaypalScriptLoaded(event: any): void {
    LoggerService.log('Is paypal script loaded  ==> ', event);
  }

  getAppointmentPaypalItems(): any[] {
    const items: {
      name: string,
      quantity: number,
      unit_amount: {
        value: string,
        currency_code: string,
      }
    }[] = [];

    for (const cartItem of this.bookingService.selectedBookingData.cartItems) {
      if (cartItem.service.price > 0) {
        items.push({
          name: cartItem.service.name + '',
          quantity: 1,
          unit_amount: {
            value: cartItem.service.price + '',
            currency_code: this.bookingService.partnerData.currency
          }
        });
      } else {
        items.push({
          name: cartItem.service.name + '',
          quantity: 1,
          unit_amount: {
            value: 0 + '',
            currency_code: this.bookingService.partnerData.currency
          }
        });
      }
    }
    LoggerService.log('Final paypal items ', items);
    return items;
  }
}
