@if (customerFields.includes('prename')) {
  <div class="mb-1">
    <label class="mb-1" for="firstName">{{ 'form_fields.preName' | translate | addRequired }}</label>
    <input
      type="text"
      class="form-control"
      id="firstName"
      required="true"
      name="forename"
      [(ngModel)]="widgetService.customerConfData.prename"
      #forename="ngModel"
      [ngClass]="{ 'is-invalid': forename.touched && forename.invalid }"
    >
    @if (forename.touched && forename.invalid) {
      <div class="invalid-feedback">
        @if (forename.errors?.required) {
          <div>
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        }
        @if (forename.errors?.minlength) {
          <div>
            {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
          </div>
        }
        @if (forename.errors?.maxlength) {
          <div>
            {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
          </div>
        }
      </div>
    }
  </div>
}

@if (customerFields.includes('lastname')) {
  <div class="mb-1">
    <label class="mb-1" for="lastName">{{ 'form_fields.lastName' | translate | addRequired }}</label>
    <input
      type="text"
      class="form-control"
      id="lastName"
      required="true"
      name="lastName"
      [(ngModel)]="widgetService.customerConfData.lastname"
      #lastName="ngModel"
      [ngClass]="{ 'is-invalid': lastName.touched && lastName.invalid }"
    >
    @if (lastName.touched && lastName.invalid) {
      <div class="invalid-feedback">
        @if (lastName.errors?.required) {
          <div>
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        }
        @if (lastName.errors?.minlength) {
          <div>
            {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
          </div>
        }
        @if (lastName.errors?.maxlength) {
          <div>
            {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
          </div>
        }
      </div>
    }
  </div>
}

@if (customerFields.includes('email')) {
  <div class="mb-1">
    <label class="mb-1" for="email">{{ 'form_fields.email' | translate | addRequired }}</label>
    <input
      type="email"
      class="form-control"
      id="email"
      name="eMail"
      [(ngModel)]="widgetService.customerConfData.email"
      #eMail="ngModel"
      required="true"
      [pattern]="emailRegex"
      [ngClass]="{ 'is-invalid': eMail.touched && eMail.invalid }"
      [disabled]="disableEmailField"
    >
    @if (eMail.touched && eMail.invalid) {
      <div class="invalid-feedback">
        @if (eMail.errors?.required) {
          <div>
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        }
        @if (eMail.errors?.minlength) {
          <div>
            {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
          </div>
        }
        @if (eMail.errors?.maxlength) {
          <div>
            {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
          </div>
        }
        @if (eMail.errors?.email) {
          <div>
            {{ 'error_messages.email_error_message' | translate }}
          </div>
        }
        @if (eMail.errors?.pattern) {
          <div>
            {{ 'error_messages.email_error_message' | translate }}
          </div>
        }
      </div>
    }
  </div>
}

@if (customerFields.includes('city') || customerFields.includes('zip')) {
  <div class="row mb-1">
    @if (customerFields.includes('city')) {
      <div class="col-8">
        <label class="mb-1" for="city">{{ 'form_fields.city' | translate | addRequired }}</label>
        <input
          type="city"
          class="form-control"
          id="city"
          name="city"
          [(ngModel)]="widgetService.customerConfData.city"
          #city="ngModel"
          required="true"
          [ngClass]="{ 'is-invalid': city.touched && city.invalid }"
        >
        @if (city.touched && city.invalid) {
          <div class="invalid-feedback">
            @if (city.errors?.required) {
              <div>
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            }
          </div>
        }
      </div>
    }
    @if (customerFields.includes('zip')) {
      <div class="col-4">
        <label class="mb-1" for="zip">{{ 'form_fields.zip' | translate | addRequired }}</label>
        <input
          type="zip"
          class="form-control"
          id="zip"
          name="zip"
          [(ngModel)]="widgetService.customerConfData.zip"
          #zip="ngModel"
          required="true"
          [pattern]="zipRegex"
          [ngClass]="{ 'is-invalid': zip.touched && zip.invalid }"
        >
        @if (zip.touched && zip.invalid) {
          <div class="invalid-feedback">
            @if (zip.errors?.required) {
              <div>
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            }
            @if (zip.errors?.pattern) {
              <div>
                {{ 'error_messages.invalid_zip' | translate }}
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}
