import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})

export class AlertComponent {

  @Input() type: string;
  @Input() message: string;
  @Input() isAlertMessageTranslated = false;
}
