import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormsModule, NgModel, ValidationErrors } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PayPalSettingsDbModel } from '@models/partner-setting.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioAddRequiredPipe } from '@pipes/calio-add-required.pipe';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CaptchaService } from '@services/feature/captcha.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { WidgetService } from '@services/feature/widget.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { CouponFormComponent } from '@ui-lib/coupon-form/coupon-form.component';
import { CwPaypalComponent } from '@ui-lib/cw-paypal/cw-paypal.component';
import { StripeComponent } from '@ui-lib/stripe/stripe.component';
import { CswLinkComponent } from '@ui-lib/typography/csw-link/csw-link.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { Observable, Subscription, debounceTime, finalize, of, switchMap } from 'rxjs';

@Component({
    selector: 'app-payment-method-view',
    templateUrl: './payment-method-view.component.html',
    styleUrls: ['./payment-method-view.component.scss'],
    standalone: true,
    imports: [CswTextComponent, FormsModule, CswLinkComponent, CouponFormComponent, StripeComponent, CwPaypalComponent, ButtonComponent, TranslateModule, CalioSafePipe, CalioAddRequiredPipe, CalioTranslationPipe, CalioMeetingTemplatePipe, MatIconModule]
})
export class PaymentMethodViewComponent implements OnInit, OnDestroy {

  showPaypalLoader = false;
  isStripeEnabled = false;
  isOnlinePaymentMandatory = false;
  isBexioEnabled = false;
  isStoreEnabled = false;
  isPaypalEnabled = false;
  isLexOfficePaymentTypeEnabled = false;
  paypalSetting: PayPalSettingsDbModel;
  paymentType: string;
  showCouponField = false;
  stripeSuccessfulSubscription$: Subscription;
  resetStripeTokenSubscription$: Subscription;
  paypalSuccessfulSubscription$: Subscription;
  resetPaypalSubscription$: Subscription;
  lang: string;
  showError = false;
  errorTitle: string;
  errorMessage: string;
  disableAllButton = false;
  validatingCaptchaChallenge = false;
  captchaInput: string;

  @Output() previousPageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdditionalServiceEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('captcha') captchaModel: NgModel;

  constructor(
    public bookingService: BookingService,
    private helperService: HelperService,
    public widgetService: WidgetService,
    private customEventService: CustomEventService,
    private translate: TranslateService,
    private captchaService: CaptchaService
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.stripeSuccessfulSubscription$ = this.customEventService.stripeSuccessfulEvent.subscribe(result => {
      this.resetError();
      this.bookingService.selectedBookingData.paymentMethodData.stripePaymentMethodId = result;
    });

    this.resetStripeTokenSubscription$ = this.customEventService.resetStripeTokenEvent.subscribe(() => {
      this.resetError();
      this.bookingService.selectedBookingData.paymentMethodData.stripePaymentMethodId = undefined;
    });

    this.paypalSuccessfulSubscription$ = this.customEventService.paypalSuccessfulEvent.subscribe(result => {
      this.resetError();
      this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail = result;
    });

    this.resetPaypalSubscription$ = this.customEventService.resetPaypalEvent.subscribe(() => {
      this.resetError();
      this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail = undefined;
      this.showPaypalLoader = true;
      setTimeout(() => {
        this.showPaypalLoader = false;
      }, 4000);
    });

    this.checkForCaptcha();

    this.verifyOnlinePaymentStatus();
  }

  private checkForCaptcha(): void {
    this.bookingService.selectedBookingData.captcha = undefined;
    this.bookingService.selectedBookingData.selectedUser.captcha = '';

    if (Number(this.bookingService.partnerData.is_captcha_feature_enabled) === 1) {
      this.captchaService.generateCaptcha().subscribe({
        next: (data) => {
          if (data) {
            this.bookingService.selectedBookingData.captcha = data;
            setTimeout(() => {
              this.captchaModel.control.setValue('');
              this.captchaModel.control.addAsyncValidators(this.captchaValidator());
            }, 500);
          }
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
    }
  }

  private captchaValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.validatingCaptchaChallenge = true;

      return this.captchaService.validateCaptcha(
        this.bookingService.selectedBookingData.captcha.uuid,
        control.value
      ).pipe(
        debounceTime(2000),
        switchMap(result => {
          control.markAsTouched();
          if (!result) {
            return of({ invalidCaptcha: true });
          }
          this.bookingService.selectedBookingData.selectedUser.captcha = control.value;
          return of(null);
        }),
        finalize(() => this.validatingCaptchaChallenge = false)
      );
    };
  }


  isCaptchaValidated(): boolean {
    if (Number(this.bookingService.partnerData.is_captcha_feature_enabled) === 1) {
      return this.bookingService.selectedBookingData.selectedUser.captcha ? true : false;
    }
    return true;
  }

  verifyOnlinePaymentStatus(): void {
    this.bookingService.verifyOnlinePaymentStatus().subscribe({
      next: result => {
        if (result) {
          if (result.isServiceOnlinePaymentEnabled === 1 && result.stripeEnabled) {
            this.isStripeEnabled = true;
            this.helperService.loadStripeJs();
          }
          if (result.isOnlinePaymentMandatory === 1) {
            this.isOnlinePaymentMandatory = true;
          }
          if (result.isBexioAppointmentServicePaymentEnabled === 1 && result.bexioEnabled) {
            this.isBexioEnabled = true;
          }
          if (result.paypalEnabled) {
            this.isPaypalEnabled = true;
            this.paypalSetting = new PayPalSettingsDbModel();
            this.paypalSetting.access_token = result.paypalAccessToken;
            this.paypalSetting.client_id = result.paypalClientId;
          }
          if (result.onlinePaymentShowOnSitePaymentType === 1 && result.isOnlinePaymentMandatory !== 1) {
            this.isStoreEnabled = true;
          }

          this.isLexOfficePaymentTypeEnabled = (result.lexOfficeEnabled === true);
        }
        this.paymentType = undefined;
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  onPaymentTypeChange(event: string): void {
    this.resetError();
    this.paymentType = event;
    this.setSelectedPaymentType(event);
  }

  previous(): void {
    this.previousPageEvent.emit();
  }

  bookAdditionalService(): void {
    this.onAdditionalServiceEvent.emit();
  }

  book(): void {
    if (this.isPaymentTypeValidated() && this.isCaptchaValidated()) {
      this.disableAllButton = true;
      this.customEventService.doBookingEvent.emit();
    } else {
      if (!this.paymentType) {
        this.setError('Please select payment option', 'Booking Error');
      }
    }
  }

  setSelectedPaymentType(paymentType: string): void {
    this.bookingService.selectedBookingData.paymentMethodData.paymentType = paymentType;
  }

  isPaymentTypeValidated(): boolean {
    if (this.bookingService?.selectedBookingData?.totalBookingAmount <= 0) {
      this.paymentType = 'store';
      this.setSelectedPaymentType(this.paymentType);
      return true;
    }

    if (!this.isBexioEnabled && !this.isStripeEnabled && !this.isPaypalEnabled && !this.isLexOfficePaymentTypeEnabled) {
      // Add comment online payment is not active
      this.paymentType = 'store';
      this.setSelectedPaymentType(this.paymentType);
      return true;
    } else {
      if (this.isOnlinePaymentMandatory) {
        if ((this.bookingService.selectedBookingData.paymentMethodData.stripePaymentMethodId
          && this.paymentType === 'creditcard')
          || this.paymentType === 'invoice'
          || (this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail && this.paymentType === 'paypal')
        ) {
          return true;
        } else {
          // TODO it should catch not valid stripe credit cards or payment type is store / null
          this.setError(`The provider has defined that the service must be paid online in advance. Therefore please select the payment method "credit card", "paypal" or "invoice".`, 'Booking Error');
          return false;
        }
      } else {
        if (this.paymentType === 'creditcard') {
          if (this.bookingService.selectedBookingData.paymentMethodData.stripePaymentMethodId) {
            return true;
          } else {
            return false;
          }
        } else if (this.paymentType === 'invoice') {
          return true;
        } else if (this.paymentType === 'store') {
          return true;
        } else if (this.paymentType === 'lex_office_invoice') {
          return true;
        } else if (this.paymentType === 'paypal') {
          if (this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail) {
            return true;
          } else {
            return false;
          }
        } else {
          LoggerService.warn(this.paymentType);
        }
      }
    }
  }

  setError(message: string, title: string): void {
    this.errorMessage = message;
    this.errorTitle = title;
    this.showError = true;
  }

  resetError(): void {
    this.errorMessage = undefined;
    this.errorTitle = undefined;
    this.showError = false;
  }

  toggleCoupon(): void {
    this.showCouponField = !this.showCouponField;
  }

  ngOnDestroy(): void {
    this.stripeSuccessfulSubscription$?.unsubscribe();
    this.resetStripeTokenSubscription$?.unsubscribe();
    this.paypalSuccessfulSubscription$?.unsubscribe();
    this.resetPaypalSubscription$?.unsubscribe();
  }
}
