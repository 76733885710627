import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Observable, map} from 'rxjs';
import {CssOverwrites, CUSTOM_CSS_WIDGET_TYPE} from '../../models/css-overwrites.model';
import {HttpClientService} from '../utils/http-client.service';
import {CustomEventService} from './custom-event.service';
import {WidgetService} from './widget.service';

@Injectable({
  providedIn: 'root'
})
export class CssOverwriteService {

  constructor(
    private http: HttpClientService,
    private widgetService: WidgetService,
    private route: ActivatedRoute,
    private customEventService: CustomEventService
  ) { }

  getCssOverwrites(): Observable<CssOverwrites> {
    return this.http.get(`css_overwrites/active?type=${CUSTOM_CSS_WIDGET_TYPE.smart_widget}&smart_widget_configuration_id=${this.widgetService.widgetConf.id}` , {});
  }

  getCssOverwriteUsingUuid(uuid: string, isPreview = false): Observable<CssOverwrites> {
    let params = new HttpParams();
    if (isPreview) { params = params.append('preview', true); }

    return this.http.get('css_overwrites/' + uuid, { params })
      .pipe(
        map(result => {
          if (result?.errors !== undefined) {
            return null;
          }
          return result;
        })
      );
  }

  checkForCssTemplateOrActiveCss(): void {
    this.route.queryParams.subscribe({
      next: (result: Params) => {
        if (result?.template) {
          this.customEventService.cssOverwriteTemplateUuid.emit({
            templateUuid: result?.template
          });
        } else {
          this.customEventService.cssOverwriteTemplateUuid.emit();
        }
      }
    });
  }
}
