import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DATE_FORMAT } from '../../constants/date.constants';
import { CustomerDbModel } from '../../models/customer.model';
import { LeadForm } from '../../models/lead.model';
import { UserInfo } from '../../models/user.model';
import { WidgetTemplateModel } from '../../models/widget-template.model';
import { WorkerDbModel } from '../../models/worker-db.model';
import { DateUtilService } from '../utils/date-util.service';
import { HttpClientService } from '../utils/http-client.service';
import { LoggerService } from '../utils/logger.service';

@Injectable({
  providedIn: 'root'
})
export class LeadGeneratorService {

  readonly dateFormat = DATE_FORMAT;

  widgetTemplates: WidgetTemplateModel[] = [];
  selectedWorker: WorkerDbModel;

  constructor(
    private httpClientService: HttpClientService,
    private dateUtilService: DateUtilService,
  ) {
  }

  createLead(leadForm: LeadForm): Observable<{ customer: CustomerDbModel }> {
    return this.httpClientService.post('customers/lead', leadForm, {});
  }

  getWorkersByIds(ids: number[]): Observable<WorkerDbModel[]> {
    const body: {
      ids: number[]
    } = { ids };
    return this.httpClientService.post('workers/get_by_ids', body, {});
  }

  getWidgetTemplates(): Observable<WidgetTemplateModel[]> {
    LoggerService.log('Inside getWidgetTemplates api call');
    return this.httpClientService.get('templates/widget', {});
  }

  preInitializeWidgetUserData(userInfo: UserInfo, customerConfData: CustomerDbModel): UserInfo {
    if (!userInfo) {
      userInfo = new UserInfo();
    }
    userInfo.eMail = customerConfData.email ? customerConfData.email : undefined;
    userInfo.forename = customerConfData.prename ? customerConfData.prename : undefined;
    userInfo.lastName = customerConfData.lastname ? customerConfData.lastname : undefined;
    userInfo.mobile = customerConfData.mobile ? customerConfData.mobile : undefined;
    userInfo.phone = customerConfData.phone ? customerConfData.phone : undefined;
    userInfo.street = customerConfData.street ? customerConfData.street : undefined;
    userInfo.comment = customerConfData.comment ? customerConfData.comment : undefined;
    userInfo.zip = customerConfData.zip ? customerConfData.zip : undefined;
    userInfo.city = customerConfData.city ? customerConfData.city : undefined;
    userInfo.gender = customerConfData.salutation ? customerConfData.salutation : undefined;
    userInfo.birthday = customerConfData.birthday ? this.dateUtilService.getDateFromString(customerConfData.birthday, this.dateFormat) : undefined;
    userInfo.customer_notification_preference = customerConfData.notification_preference ? customerConfData.notification_preference : undefined;
    userInfo.meeting_type_id = customerConfData.meeting_type_id ? customerConfData.meeting_type_id : undefined;
    userInfo.company = customerConfData?.company ? customerConfData.company : undefined;
    userInfo.title = customerConfData?.title ? customerConfData.title : undefined;
    userInfo.country = customerConfData?.country ? customerConfData.country : undefined;
    userInfo.policy = customerConfData?.policy ? customerConfData.policy : undefined;
    return userInfo;
  }
}
