import {CustomerCustomField, CustomFieldModel} from '@models/custom-field.model';

export class CustomerDbModel {
  id: number | string;
  prename: string;
  lastname: string;
  customer_name: string;
  gender: string;
  street: string;
  zip: any;
  email: string;
  phone: string;
  city: string;
  partner_id: number;
  number: number;
  customer_type_id: number;
  name: string;
  skype: string;
  url: string;
  facebook: string;
  twitter: string;
  mwst: string;
  hr: string;
  ust: string;
  employee_count: number;
  tags: string;
  country_id: number;
  address_addition: string;
  remarks: string;
  contact_person_id: number;
  worker_id: number;
  language_id: number;
  mobile: string;
  notification_preference: string;
  created: Date;
  birthday: any;
  country: number;
  language: any;
  company_name: string;
  isChecked: boolean;
  customer_notes: any[];
  customer_type: any;
  bookings: any[];
  customer_custom_fields: CustomerCustomField[];
  supportPartner: any;
  inform_customer: number | boolean;
  customFieldValues: any;
  uuid: string;
  salutation: string;
  meeting_type_id: number;
  comment: string;
  custom_fields: CustomerCustomFieldDbModel[];
  policy?: boolean;
  company?: string;
  title?: number;
}

export class CustomerCustomFieldRequestBody {
  customer_id: number;
  customFieldValues: CustomFieldValueRequestBody[] = [];
}

export class CustomFieldValueRequestBody {
  custom_field_id: number;
  value: any;
  type: string;
  option_values: {
    custom_field_option_id: number,
    custom_field_option_value: string
  }[] = [];
  appointment_id?: number;
  belongs_to_column?: number;
  entity_name?: string;
}

export class CustomerCustomFieldDbModel {
  id: number;
  uuid: string;
  customer_id: number;
  custom_field_id: number;
  custom_field: CustomFieldModel;
  customers_slots_id: number;
  date: string;
  is_past_value: number;
  parent_custom_field_option_id: number;
  value: any;
  value_hash: string;
  contact_people_id: number;
  file: CustomCustomFieldFileDbModel;
  option_value: string;
  type: string;
  appointment?: any;
  appointment_id?: number;
  belongs_to_column?: number;
  entity_name?: string;
  _history: any;
}

export class CustomCustomFieldFileDbModel {
  id: number;
  filename: string;
  origin_filename: string;
  type: string;
  uuid: string;
  virus_check_performed: number;
  size: number;
  partner_id: number;
  customer_id: number;
  full_path: string;
  custom_field_id: number;
}
