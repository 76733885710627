import {Component, Input, OnInit} from '@angular/core';
import {LoggerService} from '@services/utils/logger.service';

@Component({
    selector: 'app-gtm-operation',
    templateUrl: './gtm-operation.component.html',
    styleUrls: ['./gtm-operation.component.css'],
    standalone: true
})
export class GtmOperationComponent implements OnInit {

  @Input() gtmID: any;

  ngOnInit(): void {
    this.appendGaTrackingCode();
  }

  private appendGaTrackingCode(): void {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` + this.gtmID + `');`;
      document.head.appendChild(script);
      LoggerService.log('Google Tag manager integrated');
    } catch (error) {
      LoggerService.warn('Error appending google analytics');
      LoggerService.error(error);
    }
  }
}
