import {environment} from '../../../environments/environment';

export class LoggerService {

  constructor() {
  }

  static log(value: any, ...rest: any[]): void {
    if (!environment.production) {
      console.log(value, ...rest);
    }
  }

  static error(error: any): void {
    console.error(error);
  }

  static warn(value: any, ...rest: any[]): void {
    console.warn(value, ...rest);
  }
}
