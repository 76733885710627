import { Component, OnInit } from '@angular/core';
import { CouponDbModel } from '@models/coupon-db.model';
import { TranslateModule } from '@ngx-translate/core';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.scss'],
  standalone: true,
  imports: [FormsModule, TranslateModule],
})
export class CouponFormComponent implements OnInit {
  coupon: string;
  showError: boolean;
  showSuccessMsg: boolean;

  constructor(
    private customEventService: CustomEventService,
    private bookingService: BookingService
  ) {}

  ngOnInit(): void {
    this.customEventService.removeCouponEvent.subscribe(() => {
      this.showError = false;
      this.showSuccessMsg = false;
      this.coupon = undefined;
    });
  }

  onApplyCoupon(): void {
    this.showError = false;
    this.showSuccessMsg = false;
    this.customEventService.couponFailedEvent.emit({
      disableBookingButton: false,
      invalidCoupon: true,
    });
    // this.couponFailedEvent.emit();
    if (!this.coupon || this.coupon.trim() === '') {
      // this.customEventService.resetPayPalOrderDetailEvent.emit();
      return;
    }
    this.bookingService.applyCoupon(this.coupon).subscribe(
      (result: CouponDbModel) => {
        // LoggerService.log('result is ', result);
        if (
          result.errors &&
          result.errors.length === 1 &&
          Number(result.errors[0].code) === 404
        ) {
          this.showError = true;
          this.customEventService.couponFailedEvent.emit({
            disableBookingButton: true,
            invalidCoupon: true,
          });
          // this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else if (
          result.errors &&
          result.errors.length === 1 &&
          Number(result.errors[0].code) === 400
        ) {
          this.showError = true;
          this.customEventService.couponFailedEvent.emit({
            disableBookingButton: true,
            invalidCoupon: true,
          });
          // this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else if (
          result.errors &&
          result.errors.length === 1 &&
          Number(result.errors[0].code) === 401
        ) {
          this.showError = true;
          this.customEventService.couponFailedEvent.emit({
            disableBookingButton: true,
            invalidCoupon: true,
          });
          // this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else {
          this.showSuccessMsg = true;
          this.customEventService.couponSuccessEvent.emit(result);
        }
      },
      () => {
        this.showError = true;
        this.customEventService.couponFailedEvent.emit({
          disableBookingButton: false,
          invalidCoupon: true,
        });
      }
    );
  }
}
