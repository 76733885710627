import { NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CUSTOM_FIELD_TYPES, GTM_EVENTS } from '@constants/app.constants';
import { CustomFieldModel, CustomFieldOptionModel } from '@models/custom-field.model';
import { TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomFieldsService } from '@services/feature/custom-fields.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { BookingQuestionComponent } from '@ui-lib/booking-questions/booking-question/booking-question.component';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-booking-question-view',
  templateUrl: './booking-question-view.component.html',
  styleUrls: ['./booking-question-view.component.scss'],
  standalone: true,
  imports: [FormsModule, BookingQuestionComponent, NgTemplateOutlet, ButtonComponent, CalioTranslationPipe, CalioMeetingTemplatePipe]
})
export class BookingQuestionViewComponent implements OnInit {

  @Input() widgetQuestion: CustomFieldModel;
  @Input() isLast: boolean;
  @Input() isFirst: boolean;

  @Output() previousQuestionEvent = new EventEmitter<any>();
  @Output() nextQuestionEvent = new EventEmitter<any>();
  @Output() finishLastBookingQuestionEvent = new EventEmitter<any>();

  @ViewChild('bookingQuestionForm') public bookingQuestionForm: NgForm;

  protected showDependenciesGhostElement = false;
  protected dependenciesGhostElements = [1, 2, 3];
  protected isEndOfProcessByChildDependencies = false;
  protected lang: string;
  protected isNextButtonPressed = false;
  protected isGTMEventSent: boolean;

  constructor(
    private customFieldsService: CustomFieldsService,
    private helperService: HelperService,
    public widgetService: WidgetService,
    public bookingService: BookingService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.isGTMEventSent = false;
  }

  onRadioButtonChangeEvent(event: {
    customField: CustomFieldModel,
    customFieldOption: CustomFieldOptionModel,
    oldCustomFieldDependencyIds: number[],
    removeOldCustomFieldDependencyIds: boolean,
  }): void {
    this.isEndOfProcessByChildDependencies = false;
    if (event?.oldCustomFieldDependencyIds?.length > 0 && event.removeOldCustomFieldDependencyIds) {
      this.bookingService.selectedBookingData.selectedCustomFieldValues =
        this.bookingService.removeCustomFieldValuesByIds(
          event?.oldCustomFieldDependencyIds,
          this.bookingService?.selectedBookingData?.selectedCustomFieldValues);
      this.bookingService.setCustomFieldValuesInLocalStorage(this.bookingService.selectedBookingData.selectedCustomFieldValues);
    }

    if (event?.customFieldOption) {
      let dependencies: string[] = [];

      if (
        Array.isArray(event?.customFieldOption) &&
        event?.customFieldOption?.length &&
        event.customFieldOption[0].dependencies &&
        event.customFieldOption[0].dependencies?.trim() !== ''
      ) {
        dependencies = event.customFieldOption[0].dependencies.split(',');
      } else if (event?.customFieldOption?.dependencies && event?.customFieldOption?.dependencies?.trim() !== '') {
        dependencies = event?.customFieldOption?.dependencies.split(',');
      }

      if (dependencies?.length > 0) {
        const cfDependencies = dependencies.map(i => Number(i));
        this.getCustomFieldDependencies(
          event.customField,
          cfDependencies
        );
      }
    }

    if (event.customField?.child_custom_field_id) {
      const customerSelectedValue = Array.isArray(event.customField.customerSelectedValue) && event.customField.customerSelectedValue?.length
        ? event.customField.customerSelectedValue[0]
        : event.customField.customerSelectedValue;
      if (customerSelectedValue) {
        this.getCustomFieldDependencies(
          event.customField,
          [event.customField.child_custom_field_id]
        );
      } else {
        delete event.customField.customFieldDependencies;
      }
    }
  }

  getCustomFieldDependencies(
    customFieldData: CustomFieldModel,
    cfDependencies: number[],
  ): void {
    this.isNextButtonPressed = false;
    this.showDependenciesGhostElement = true;
    this.customFieldsService
      .getCustomFieldsByIds(cfDependencies)
      .pipe(finalize(() => this.showDependenciesGhostElement = false))
      .subscribe({
        next: customFields => {
          if (customFields.length > 0) {
            for (const customField of customFields) {
              if (customField.is_end_of_process === 1) {
                this.isEndOfProcessByChildDependencies = true;
              }
              if ([CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT, CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO].includes(customField.type)) {
                if (customField.alphabetically_sorted === 1) {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'label');
                } else {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'position');
                }
              }
            }

            for (const customField of customFields) {
              if (customField.is_hidden === 1) {
                this.bookingService.hiddenCustomFields.push(customField);
              }
            }

            customFields = customFields.filter(customField => customField.is_hidden === 0);

            customFieldData.customFieldDependencies = customFields;
          } else {
            // Handle this case if dependencies are undefined
          }
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  customFieldChangeDetection(event: any): void {
    this.sendQuestionStartGtmEvent();

    // Proceeds to the next step if the one-page UI is enabled.
    setTimeout(() => {
      if (this.widgetService.widgetConf?.context?.enable_one_page_ui) {
        !event?.dependencies && this.bookingQuestionForm?.valid && this.finishLastBookingQuestion();
      }
    }, 200);
  }

  previousQuestion(): void {
    if (this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_questions_end);
    }
    this.isNextButtonPressed = false;
    this.isEndOfProcessByChildDependencies = false;
    this.previousQuestionEvent.emit();
  }

  nextQuestion(): void {
    this.isNextButtonPressed = true;
    if (this.bookingQuestionForm?.valid) {
      this.isEndOfProcessByChildDependencies = false;
      this.sendQuestionStartGtmEvent(true);
      this.nextQuestionEvent.emit();
    } else {
      this.bookingQuestionForm?.control?.markAllAsTouched();
    }
  }

  finishLastBookingQuestion(): void {
    this.isNextButtonPressed = true;
    if (this.bookingQuestionForm?.valid) {
      this.sendQuestionStartGtmEvent(true);
      this.finishLastBookingQuestionEvent.emit();
    } else {
      this.bookingQuestionForm?.control?.markAllAsTouched();
    }
  }

  sendQuestionStartGtmEvent(endPreviousEvent = false): void {
    // trigger start event on first user interaction happen and
    // question step is configured to first step
    this.bookingService.triggerApoointmentStartEvent();

    if (endPreviousEvent && !this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_questions_start);
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_questions_end);
      this.isGTMEventSent = true;
    } else if (endPreviousEvent && this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_questions_end);
    } else if (!endPreviousEvent && !this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_questions_start);
      this.isGTMEventSent = true;
    }
  }
}
