import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WidgetTemplateModel } from '@models/widget-template.model';

@Pipe({
  name: 'calioMeetingTemplate',
  standalone: true
})
export class CalioMeetingTemplatePipe implements PipeTransform {

  constructor(
    protected sanitizer: DomSanitizer,
    public translate: TranslateService,
  ) {
  }

  transform(widgetTemplates: WidgetTemplateModel[], config: {
    type: string,
    id: number,
  }): any {
    if (config.type === 'template') {
      const template: WidgetTemplateModel = widgetTemplates.find((item: WidgetTemplateModel) => {
        return (item).id === config.id;
      });
      if (template) {
        template.is_multi_language = 1;
      }
      return template;
    } else if ('meeting_template') {
      return this.getMeetingTemplate(config.id, widgetTemplates);
    } else {
      return null;
    }
  }

  getMeetingTemplate(meetingId: number, templates: WidgetTemplateModel[]): {
    titleTemplate: WidgetTemplateModel,
    descTemplate: WidgetTemplateModel
  } {
    switch (meetingId) {
      case 1:
        return this.getMeetingTitleDescTemplate(181, 182, templates);
      case 2:
        return this.getMeetingTitleDescTemplate(183, 184, templates);
      case 3:
        return this.getMeetingTitleDescTemplate(185, 186, templates);
      case 4:
        return this.getMeetingTitleDescTemplate(187, 188, templates);
      case 5:
        return this.getMeetingTitleDescTemplate(189, 190, templates);
      case 6:
        return this.getMeetingTitleDescTemplate(191, 192, templates);
      case 7:
        return this.getMeetingTitleDescTemplate(193, 194, templates);
      case 8:
        return this.getMeetingTitleDescTemplate(195, 196, templates);
      case 9:
        return this.getMeetingTitleDescTemplate(197, 198, templates);
      case 10:
        return this.getMeetingTitleDescTemplate(199, 200, templates);
      case 11:
        return this.getMeetingTitleDescTemplate(201, 202, templates);
      case 13:
        return this.getMeetingTitleDescTemplate(243, 244, templates);
    }
  }

  getMeetingTitleDescTemplate(
    titleId: number,
    descId: number,
    templates: WidgetTemplateModel[]
  ): {
    titleTemplate: WidgetTemplateModel,
    descTemplate: WidgetTemplateModel
  } {
    let titleTemplate: WidgetTemplateModel;
    let descTemplate: WidgetTemplateModel;
    titleTemplate = templates.find((template: WidgetTemplateModel) => {
      return (template).id === titleId;
    });
    if (titleTemplate) {
      titleTemplate.is_multi_language = 1;
    }
    descTemplate = templates.find((template: WidgetTemplateModel) => {
      return (template).id === descId;
    });
    if (descTemplate) {
      descTemplate.is_multi_language = 1;
    }
    return {
      titleTemplate, descTemplate
    };
  }
}
