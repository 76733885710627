import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokenService } from '@services/utils/auth-token.service';

export const AuthTokenInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authTokenService = inject(AuthTokenService);
  const authToken = authTokenService.getToken();
  if (authToken) {
    const headers: { [name: string]: string } = {};
    headers['Authorization'] = `Bearer ${authToken}`;

    const tokenReq = request.clone({
      setHeaders: headers,
    });
    return next(tokenReq);
  }
  return next(request);
};
