import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '@app/services/utils/logger.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: HttpErrorResponse): void {
    customErrorHandlerForChunkFailed(error);
    Sentry.createErrorHandler({ showDialog: false });
  }
}

export const customErrorHandlerForChunkFailed = (error: Error) => {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  if (error) {
    if (chunkFailedMessage.test(error?.message)) {
      if (confirm("A new version is available! Would you like to load it now?\n\nNote: Clicking 'Ok' will reload the page with the latest updates.")) {
        window.location.reload();
      }
    } else {
      LoggerService.error(error);
    }
  }
}