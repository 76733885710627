import { NgClass } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS, WIDGET_TEMPLATES_CONST, WORKERS_VIEW } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { ServiceWorkerModel } from '@models/appointment-service.model';
import { CurrentViewData } from '@models/widget-conf.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { WorkerDbModel } from '@models/worker-db.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { SwRouteService } from '@services/utils/sw-route.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';

@Component({
  selector: 'app-workers-selection',
  templateUrl: './workers-selection.component.html',
  styleUrls: ['./workers-selection.component.scss'],
  standalone: true,
  imports: [FormsModule, CswTextComponent, NgSelectModule, NgClass, InfoCardComponent, ButtonComponent, TranslateModule, CalioSafePipe, CalioTranslationPipe, CalioMeetingTemplatePipe]
})
export class WorkersSelectionComponent implements OnInit {

  readonly workerAvatarBaseUrl = environment.workerAvatarBaseUrl;
  readonly baseUrl = environment.hostName;
  readonly storeBaseUrl = environment.storeBaseUrl;
  readonly workersViews = WORKERS_VIEW;

  workers: WorkerDbModel[] = [];
  selectedWorkerId: number;
  selectedWorker: WorkerDbModel;
  lang: string;
  widgetAnyoneLabelTemplate: WidgetTemplateModel;
  widgetAnyoneDescTemplate: WidgetTemplateModel;
  widgetResourceHeadingTemplate: WidgetTemplateModel;
  isGTMEventSent: boolean;
  widgetShowResourceStoreName = 0;
  contextStoreUuids: string[] = [];
  workersView: string;
  allowMultipleNavigations = true;
  showWorkerSelectionRequiredError = false;

  @Output() nextPageEvent = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();

  constructor(
    public translate: TranslateService,
    public widgetService: WidgetService,
    public bookingService: BookingService,
    private swRouteService: SwRouteService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helperService: HelperService
  ) {
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreUuids?.length) {
      this.contextStoreUuids = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreUuids;
    }

    this.widgetShowResourceStoreName = Number(this.bookingService.partnerData?.widget_show_resource_store_name);
    let checkForAutoNavigattion = false;
    this.isGTMEventSent = false;
    this.lang = this.translate.getDefaultLang();
    this.bookingService.loadAppointmentStateFromLocalStorage();

    setTimeout(() => {
      this.findWorkers();

      if (
        this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId ||
        this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId === 0
      ) {
        checkForAutoNavigattion = true;
        this.selectedWorkerId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId;
        if (!this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorker) {
          this.onWorkerChange(null, false);
        } else {
          this.selectedWorker = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorker;
        }
      } else {
        if (this.widgetService.widgetConf?.context?.worker_id) {
          this.selectedWorkerId = this.widgetService.widgetConf?.context?.worker_id;
          this.onWorkerChange(null, false);
          checkForAutoNavigattion = true;
        } else if (this.widgetService.widgetConf?.context?.worker_uuid) {
          const worker = this.workers.find(worker => worker.uuid === this.widgetService.widgetConf?.context?.worker_uuid);
          if (worker) {
            this.selectedWorkerId = worker.id;
            this.onWorkerChange(null, false);
            checkForAutoNavigattion = true;
          }
        } else if (this.widgetService.widgetConf?.context?.worker_email) {
          const worker = this.workers.find(worker => worker.email === this.widgetService.widgetConf?.context?.worker_email);
          if (worker) {
            this.selectedWorkerId = worker.id;
            this.onWorkerChange(null, false);
            checkForAutoNavigattion = true;
          }
        }
      }

      if (
        checkForAutoNavigattion === true &&
        this.allowMultipleNavigations &&
        this.bookingService.lastView !== BOOKING_PAGE_VIEWS.SLOTS_VIEW &&
        this.swRouteService.isAutoSkipStepsEnabled()
      ) {
        this.onWorkerChange(null, false);
        this.next(false);
      }
    }, 200);

    this.setupTemplates();
  }

  findWorkers(): void {
    let workers: number[] = [];
    let serviceWorkers: WorkerDbModel[] = [];

    const currentSelectedServices = this.bookingService.selectedBookingData.selectedServices.filter(item => {
      return this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(item.id) > -1;
    });

    for (const selectedService of currentSelectedServices) {
      if (selectedService.services_workers?.length > 0) {
        workers = workers.concat(selectedService.services_workers.map((item: ServiceWorkerModel) => item.worker_id));
        workers = [...new Set(workers)];


        serviceWorkers = serviceWorkers.concat(selectedService.services_workers.map((item: ServiceWorkerModel) => item.worker));
        if (
          this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
          this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1
        ) {
        } else {
          if (
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId &&
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId !== 0
          ) {
            serviceWorkers = serviceWorkers.filter(item => {
              // if -> when store_uuids are present in context then show all the workers are from mentioned stores (This has been updated for AMAG)
              // else -> show worker for selected store only
              if (this.contextStoreUuids?.length) {
                return item?.id && this.contextStoreUuids.includes(item?.store?.uuid);
              } else {
                return item?.id && item?.store?.id === this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
              }
            });
          }
        }

        serviceWorkers = serviceWorkers?.filter(item => Number(item?.bookable) === 1);
        serviceWorkers = this.getUniqueListBy(serviceWorkers, 'id');
      }
    }

    // define view of workers, in case it is hardcoded from context using `workers_view` (This has been updated for AMAG)
    if (serviceWorkers?.length) {
      if ([this.workersViews.CARD, this.workersViews.DROPDOWN].includes(this.widgetService.widgetConf?.context?.workers_view)) {
        this.workersView = this.widgetService.widgetConf.context.workers_view;
      } else if (serviceWorkers?.length <= 10) {
        this.workersView = this.workersViews.CARD;
      } else if (serviceWorkers?.length > 10) {
        this.workersView = this.workersViews.DROPDOWN;
      }
    }

    this.workers = this.helperService.sortBy(serviceWorkers, 'resource_name');

    // When length of storeDefaultWorkerIds is > 0 means, store has multiple mapped workers which needs to be filtered out first
    // before moving further
    if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds?.length) {
      const filteredWorker = this.workers.filter(worker => this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds.includes(worker.id));
      if (filteredWorker?.length) {
        LoggerService.warn('[Default store worker]: Default store workers are present so overwriting all the supported workers by default worker');
        this.workers = filteredWorker;
      }
    }

    // When is_widget_postcode_worker_random_selection_enabled setting is enable and default worker from postcode
    // is null then worker selection will be random and selection will be skipped
    if (Number(this.bookingService.partnerData.is_widget_postcode_worker_random_selection_enabled) === 1) {
      // If default worker from postcode is null then worker selection will be random and selection will be skipped
      // else case is to check if user is coming back from slot step to worker then we should skip it as
      // is_widget_postcode_worker_random_selection_enabled setting is enabled

      if (
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds ||
        !this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId
      ) {
        if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.SLOTS_VIEW) {
          this.allowMultipleNavigations = false;
          this.previous();
          return;
        }

        const randomIndex = Math.floor(Math.random() * this.workers.length);
        this.selectedWorkerId = this.workers[randomIndex].id;
        this.onWorkerChange(null, false);
        if (
          this.bookingService.lastView !== BOOKING_PAGE_VIEWS.SLOTS_VIEW &&
          this.swRouteService.isAutoSkipStepsEnabled()
        ) {
          this.next(false);
        }
        return;
      } else if (
        this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId &&
        this.bookingService.lastView === BOOKING_PAGE_VIEWS.SLOTS_VIEW
      ) {
        if (Number(this.bookingService.partnerData.is_widget_postcode_worker_random_selection_enabled) === 1) {
          this.allowMultipleNavigations = false;
          this.previous();
        }
        return;
      }
    } else {
      if (this.workers?.length === 1) {
        if (!this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId) {
          this.selectedWorkerId = this.workers[0].id;
          this.onWorkerChange(null, false);
          if (
            this.bookingService.lastView !== BOOKING_PAGE_VIEWS.SLOTS_VIEW &&
            this.swRouteService.isAutoSkipStepsEnabled()
          ) {
            this.next(false);
          }
          return;
        }
      }
    }
  }

  getUniqueListBy(arr, key): any {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  onWorkerChange(event: any, isUserInteracted = true): void {

    // Reset Error
    this.showWorkerSelectionRequiredError = false;

    if (isUserInteracted) {
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_resources_start);
        this.isGTMEventSent = true;
      }
    }
    if (this.selectedWorkerId === 0) {
      this.selectedWorker = new WorkerDbModel();
      this.selectedWorker.id = 0;
    } else {
      this.selectedWorker = this.workers.find((item: WorkerDbModel) => {
        return item.id === this.selectedWorkerId;
      });
    }

    // Proceeds to the next step if the one-page UI is enabled.
    this.widgetService.widgetConf?.context?.enable_one_page_ui && this.next();
  }

  next(isUserInteracted = true): void {

    // Worker is is not selected and clicked on next then show error.
    if (this.selectedWorkerId === null || this.selectedWorkerId === undefined) {
      this.showWorkerSelectionRequiredError = true;
      return;
    }

    if (isUserInteracted) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_resources_end);
    }

    if (this.selectedWorker) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = this.selectedWorker;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = this.selectedWorker.id;
      this.bookingService.setAppointmentStateInLocalStorage(this.bookingService.selectedBookingData.currentSelectedAppointmentState);

      // set store_id on worker select when store_uuids are present in context(This has been updated for AMAG)
      if (this.contextStoreUuids?.length) {
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = this.selectedWorker.store_id;
      }

      this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.SLOTS_VIEW, isUserInteracted });
    }
  }

  previous(): void {
    if (this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_resources_end);
    }
    this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.SERVICES_VIEW, isUserInteracted: true });
  }

  setupTemplates(): void {
    this.widgetAnyoneLabelTemplate = this.bookingService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_ANYONE_LABEL;
    });
    if (this.widgetAnyoneLabelTemplate) {
      this.widgetAnyoneLabelTemplate.is_multi_language = 1;
    }

    this.widgetAnyoneDescTemplate = this.bookingService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_ANYONE_DESC_LABEL;
    });
    if (this.widgetAnyoneDescTemplate) {
      this.widgetAnyoneDescTemplate.is_multi_language = 1;
    }

    this.widgetResourceHeadingTemplate = this.bookingService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_RESOURCE_LABEL;
    });
    if (this.widgetResourceHeadingTemplate) {
      this.widgetResourceHeadingTemplate.is_multi_language = 1;
    }
  }
}
