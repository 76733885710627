import { MeetingTypesModel } from '@models/meeting-types.model';

export class UserInfo {
  forename?: string;
  lastName?: string;
  eMail?: string;
  phone?: string;
  comment?: string;
  customerId?: number;
  street?: string;
  zip?: string;
  city?: string;
  selectedLanguage?: string;
  browserLanguage?: string;
  gender?: string;
  birthday?: Date;
  mobile?: string;
  customer_notification_preference?: string;
  meeting_type_id?: number;
  captcha?: string;
  title?: number;
  company?: string
  country?: number;
  policy?: boolean;
  // client property
  meetingType: MeetingTypesModel = null;
}
