<app-booking-question
  [prefix]="prefix"
  [surveyGlobalStep]="surveyGlobalStep"
  [customField]="widgetQuestion"
  [titleStyleConfig]="{ fontSize: '20px', fontWeight: '600' }"
  [labelStyleConfig]="{ fontSize: '16px', fontWeight: '500' }"
  [textContainerStyleConfig]="{ marginBottom: '26px' }"
  [customerDataIsNotMandatoryToUploadFiles]="false"
  [deactivateField]="
    !isFirst &&
    !currentSurveyStep?.booking_questions[currentIndex - 1]?.customerSelectedValue &&
    currentSurveyStep?.booking_questions[currentIndex - 1]?.required === 1 &&
    currentSurveyStep?.booking_questions[currentIndex - 1]?.is_read_only === 0
  "
  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
  (changeCustomFieldSelectedValueEvent)="onChangeCustomFieldSelectedValueEvent(true, widgetQuestion, $event)"
  (blurCustomFieldSelectedValueEvent)="onBlurCustomFieldSelectedValueEvent($event)"
/>
<!-- dependent question of the questions -->
@if (widgetQuestion?.customFieldDependencies?.length) { 
  @for (dependencyCustomField of widgetQuestion?.customFieldDependencies; track dependencyCustomField; let dependentQIndex = $index) {
    <app-booking-question
      [prefix]="prefix + '.' + (dependentQIndex + 1)"
      [surveyGlobalStep]="surveyGlobalStep"
      [customField]="dependencyCustomField"
      [parentField]="widgetQuestion"
      [titleStyleConfig]="{ fontSize: '20px', fontWeight: '500' }"
      [labelStyleConfig]="{ fontSize: '16px', fontWeight: '500' }"
      [textContainerStyleConfig]="{ marginBottom: '26px' }"
      [customerDataIsNotMandatoryToUploadFiles]="false"
      (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
      (changeCustomFieldSelectedValueEvent)="onChangeCustomFieldSelectedValueEvent(false, dependencyCustomField, $event)"
      (blurCustomFieldSelectedValueEvent)="onBlurCustomFieldSelectedValueEvent($event)"
    >
      <!-- dependent question of the selected options -->
      @if (dependencyCustomField?.loadOptionDependencies === true) {
        <div class="row">
          @for (dependendentOptionCustomFields of dependencyCustomField?.optionDependentQuestions; track dependendentOptionCustomFields; let dependentOQIndex = $index) {
            <div [class]="'border-end no-right-border-last-child border-sm-right-none col-md-' + 12 / (widgetQuestion?.generate_entity_count ? widgetQuestion.generate_entity_count : 1)">
              @for (dependendentOptionCustomField of dependendentOptionCustomFields; track dependendentOptionCustomField) {
                <app-booking-question
                  [customEventIndex]="dependentOQIndex"
                  [deactivateField]="
                    dependencyCustomField?.optionDependentQuestionIds[dependentOQIndex] &&
                    dependencyCustomField?.optionDependentQuestionIds[dependentOQIndex].includes(dependendentOptionCustomField.id) ? false : true
                  "
                  [surveyGlobalStep]="surveyGlobalStep"
                  [customField]="dependendentOptionCustomField"
                  [parentField]="dependencyCustomField"
                  [grandParentField]="widgetQuestion"
                  [titleStyleConfig]="{ fontSize: '20px', fontWeight: '500' }"
                  [labelStyleConfig]="{ fontSize: '16px', fontWeight: '500' }"
                  [textContainerStyleConfig]="{ marginBottom: '26px' }"
                  [customerDataIsNotMandatoryToUploadFiles]="false"
                  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
                  (changeCustomFieldSelectedValueEvent)="onChangeCustomFieldSelectedValueEvent(false, dependendentOptionCustomField,$event)"
                  (blurCustomFieldSelectedValueEvent)="onBlurCustomFieldSelectedValueEvent($event)"
                />
              }
            </div>
          }
        </div>
      }
      <!-- dependent question of the selected options -->
    </app-booking-question>
  } 
} 
@if (showDependenciesGhostElement) {
  <div class="skeleton-box-card mt-4">
    <div class="row">
      @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
        <div class="col-md-12 mb-4">
          <div class="d-flex stats-card shadow">
            <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
              <div class="stat-box-1"></div>
              <div class="stat-box mt-1"></div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
