import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DATE_ISO_FORMAT } from '../../constants/date.constants';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {

  readonly dateIsoFormat = DATE_ISO_FORMAT;

  getDateByPlaceholder(placeholder: string): string {
    switch (placeholder) {
      case '[TODAY]':
        return moment().format(this.dateIsoFormat);
      case '[TOMORROW]':
        return moment().add(1, 'd').format(this.dateIsoFormat);
      default:
        return placeholder;
    }
  }

  getDateFromString(date: string, format: string): Date {
    return moment(date, format).toDate();
  }

  getFormattedDateByFormat(date: Date, format: string): string {
    return moment(date).format(format);
  }

  getStartOf(date: Date, unit: any): Date {
    return moment(date).startOf(unit).toDate();
  }

  isSame(firstDate: Date, secondDate: Date, granularity?: any): boolean {
    if (granularity) {
      return moment(firstDate).isSame(secondDate, granularity);
    } else {
      return moment(firstDate).isSame(secondDate);
    }
  }
}
