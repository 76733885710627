export const environment = {
  version: '7.1.2',
  production: false,
  restAPI: 'https://dev.my.calenso.com/api/v1/',
  bookingWebcomponentUrl: 'https://dev.webcomponent.calenso.com/booking.html',
  hostName: 'https://dev.my.calenso.com',
  workerAvatarBaseUrl: 'https://dev.my.calenso.com/webroot/files/workers/avatar/',
  storeBaseUrl: 'https://dev.my.calenso.com/webroot/files/stores/logo/',
  deployUrl: 'https://dev.smartwidget.calenso.com/',
  stripeAPIKey: 'pk_test_qC6Q2zbwLgHgHEzXkHdKATbR',
  encryptedKey: '6LfB5EchAAAAAMiJapMV9GumBAQBfKqboRWT5she'
};
