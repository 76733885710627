@if (!isLink) {
  <a class="anchor-link" href="javascript:void(0)" (click)="onLinkClick()"
    [ngClass]="{ 'show-under-line': showUnderLine  }"
    [ngStyle]="styleConfig"
  >
    <span [ngStyle]="styleConfig">{{ text }}</span>
  </a>
}
@if (isLink) {
  <a class="anchor-link" [href]="link" (click)="onLinkClick()"
    [target]="target"
    [ngClass]="{ 'show-under-line': showUnderLine  }"
    [ngStyle]="styleConfig"
  >
    <span [ngStyle]="styleConfig">{{ text }}</span>
  </a>
}
