@if (widgetService.partnerUUID) {
  <link
    rel="stylesheet"
    type="text/css"
    [href]="'/assets/css/lead/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css' | calioSafe : 'resourceUrl'"
  />
}
<div class="lead-generator">
  @if (showQuestionsScreen) {
    <app-booking-questions (submitEvent)="onSubmitEvent($event)" />
  } 
  @if (showAlertScreen && alertType && alertMessage) {
    <app-alert
      [isAlertMessageTranslated]="isAlertMessageTranslated"
      [type]="alertType"
      [message]="alertMessage"
    />
  }
</div>
