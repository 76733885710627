import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomEventService} from '@services/feature/custom-event.service';
import {LoggerService} from '@services/utils/logger.service';
import {environment} from '@environments/environment';
import { FormsModule } from '@angular/forms';

declare var Stripe: any;

@Component({
    selector: 'app-stripe',
    templateUrl: './stripe.component.html',
    styleUrls: ['./stripe.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class StripeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  error: string;
  stripe: any;
  elements: any;

  paymentIntentClientSecret: { secret: string };

  constructor(
    private cd: ChangeDetectorRef,
    public translate: TranslateService,
    private customEventService: CustomEventService
  ) {
  }

  ngAfterViewInit(): void {
    const style = {
      base: {
        color: '#495057',
        lineHeight: '24px',
        // fontFamily: 'Open Sans',
        fontSmoothing: 'antialiased',
        fontSize: '13px',
        '::placeholder': {
          color: '#495057'
        }
      }
    };
    this.card = this.elements.create('card', {style, hidePostalCode: true});
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.onChange.bind(this));
    this.card.addEventListener('blur', this.onSubmit.bind(this));
  }

  ngOnDestroy(): void {
    if (this.card) {
      this.card.removeEventListener('change', this.onChange.bind(this));
      this.card.removeEventListener('blur', this.onSubmit.bind(this));
      this.card.destroy();
    }
  }

  onChange({error}): void {
    this.customEventService.resetStripeTokenEvent.emit();
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(): Promise<any> {

    // TODO STRIPE_MIGRATION Starts:
    /*const result: { error: any, paymentIntent: any }
      = await this.stripe.confirmCardPayment(
      this.paymentIntentClientSecret.secret, {
        payment_method: {
          card: this.card,
          /!*billing_details: {
            name: 'Jenny Rosen'
          }*!/
        }
      });

    LoggerService.log('result ', result);
    this.customEventService.refreshPaymentIntentEvent.emit();

    if (result.error) {
      LoggerService.log('Something is wrong:', result.error);
      this.resetStripeTokenEvent.emit();
    } else {
      LoggerService.log('Stripe payment method is :', result.paymentIntent.payment_method);
      this.stripeSuccessfulEvent.emit(result.paymentIntent.payment_method);
    }*/
    // TODO STRIPE_MIGRATION Ends:

    const {token, error} = await this.stripe.createToken(this.card);

    if (error) {
      LoggerService.log('Something is wrong:', error);
      this.customEventService.resetStripeTokenEvent.emit();
    } else {
      LoggerService.log('Stripe token is :', token);
      this.customEventService.stripeSuccessfulEvent.emit(token.id);
    }
  }

  ngOnInit(): void {
    this.stripe = Stripe(environment.stripeAPIKey);
    this.elements = this.stripe.elements();
    // TODO STRIPE_MIGRATION Starts:
    /*if (this.stripeAccountData && this.stripeAccountData.accountId) {
      this.stripe = Stripe(environment.stripeAPIKey, {
        stripeAccount: this.stripeAccountData.accountId
      });
      this.elements = this.stripe.elements();
    }

    this.customEventService.paymentIntentEvent.subscribe(
      (result: { secret: string }) => {
        LoggerService.log('paymentIntent is ', result);
        this.paymentIntentClientSecret = result;
      }
    );*/
    // TODO STRIPE_MIGRATION Ends:
  }
}
