import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarAppointmentModel } from '../../models/appointment-booking.model';
import { CouponDbModel } from '../../models/coupon-db.model';
import { PayPalOrderDetailModel } from '../../models/partner-setting.model';
import { AppointmentCartItem } from '../../models/selected-booking-data.model';

@Injectable({
  providedIn: 'root'
})
export class CustomEventService {

  refreshCalendar = new Subject<any>();
  enableSubmitButtonEvent = new EventEmitter<any>();
  setWidgetConfigurationFromQueryParamsEvent = new EventEmitter<{ partner_uuid: string, widget_uuid: string }>();
  resetMainWidgetEvent = new EventEmitter<any>();
  toggleFillContainer = new EventEmitter<boolean>();
  queryStringAppointmentUuid = new EventEmitter<string>();
  resetRecurringDatesEvent: EventEmitter<boolean> = new EventEmitter();
  resetSlotSettingsEvent = new EventEmitter<any>();
  stripeSuccessfulEvent = new EventEmitter<string>();
  resetStripeTokenEvent = new EventEmitter();
  paypalSuccessfulEvent = new EventEmitter<PayPalOrderDetailModel>();
  resetPaypalEvent = new EventEmitter();
  couponSuccessEvent = new EventEmitter<CouponDbModel>();
  couponFailedEvent = new EventEmitter();
  removeCouponEvent = new EventEmitter();
  resetPayPalOrderDetailEvent = new EventEmitter();
  doBookingEvent = new EventEmitter();
  progressBarCountEvent = new EventEmitter<{ index: number, isLast: boolean }>();
  appointmentBookingDoneEvent = new EventEmitter<{ bookingData: CalendarAppointmentModel[] }>();
  refreshCalendarBadgeInBookingEvent = new EventEmitter<{ cartItems: AppointmentCartItem[] }>();
  cssOverwriteTemplateUuid = new EventEmitter<{ templateUuid: string }>();
}
