import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS } from '@constants/app.constants';
import { CurrentViewData } from '@models/widget-conf.model';
import { TranslateModule } from '@ngx-translate/core';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';

@Component({
  selector: 'app-booking-failed-view',
  templateUrl: './booking-failed-view.component.html',
  styleUrls: ['./booking-failed-view.component.scss'],
  standalone: true,
  imports: [CswTextComponent, ButtonComponent, TranslateModule]
})
export class BookingFailedViewComponent implements OnInit {

  @Output() nextPageEvent = new EventEmitter<CurrentViewData>();

  protected errorMessage: string;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    public widgetService: WidgetService,
    private bookingService: BookingService
  ) {
  }

  ngOnInit(): void {
    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_failed);

    if (this.widgetService.widgetConf?.partner?.courtesyForm === 'Du') {
      this.errorMessage = 'booking_components.errorMessageDu'
    } else {
      this.errorMessage = 'booking_components.errorMessageSie'
    }
  }

  onAddtionalBooking(): void {
    this.bookingService.resetBookingDataAfterBooking();
    if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
      this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW_BEFORE, isUserInteracted: true });
    } else {
      if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker) {
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW, isUserInteracted: true });
      } else {
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.STORE_VIEW, isUserInteracted: true });
      }
    }
  }
}
