<div class="stripe-integration">
  <form #checkout="ngForm" (ngSubmit)="onSubmit()" class="checkout">
    <div class="row stripe-row">
      <div class="col-lg-12">
        <div id="card-info" #cardInfo></div>
      </div>
      @if (error) {
        <div id="card-errors" class="card-errors col-md-12" role="alert">{{ error }}</div>
      }
    </div>
  </form>
</div>