import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass],
})
export class InfoCardComponent implements OnInit, OnChanges {

  @Input() isSelected = false;
  @Input() isDisabled = false;
  @Input() showCardBorder = false;
  @Input() showShadow = true;
  @Input() customCardClass: string;
  @Input() cardHeight: string;
  @Input() showHeader = false;
  @Input() showBody = false;
  @Input() showFooter = false;
  @Input() showImage = false;
  @Input() selectedCardColor: string;
  @Input() showCursorPointer = false;

  defaultSelectedCardColor = '#3a4a66';

  @Input() customCardStylesConfig: {
    backgroundColor?: string;
    height?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  };

  defaultCardStylesConfig: {
    backgroundColor?: string;
    height?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  } = {
      backgroundColor: 'white',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.125)',
      borderStyle: 'solid',
      borderRadius: '8px',
    };

  cardStylesConfig: {
    backgroundColor?: string;
    height?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  } = {};

  @Output() cardClickEvent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.setCustomCardStyle();
    this.setCardHeight();
    this.setCardBackground();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCardBackground();
  }

  setCardHeight(): void {
    this.cardHeight && (this.cardStylesConfig.height = this.cardHeight);
  }

  setCardBackground(): void {
    if (this.isSelected) {
      if (this.selectedCardColor) {
        this.cardStylesConfig.backgroundColor = this.selectedCardColor + '66';
        this.cardStylesConfig.borderWidth = '1px';
        this.cardStylesConfig.borderColor = this.selectedCardColor;
        this.cardStylesConfig.borderStyle = 'solid';
      } else {
        this.cardStylesConfig.backgroundColor = this.defaultSelectedCardColor + '66';
        this.cardStylesConfig.borderWidth = '1px';
        this.cardStylesConfig.borderColor = this.defaultSelectedCardColor;
        this.cardStylesConfig.borderStyle = 'solid';
      }
    } else {
      this.cardStylesConfig.backgroundColor = '';
      if (this.showCardBorder) {
        this.cardStylesConfig.borderWidth = this.defaultCardStylesConfig.borderWidth;
        this.cardStylesConfig.borderColor = this.defaultCardStylesConfig.borderColor;
        this.cardStylesConfig.borderStyle = this.defaultCardStylesConfig.borderStyle;
        this.cardStylesConfig.borderRadius = this.defaultCardStylesConfig.borderRadius;
      } else {
        this.cardStylesConfig.borderWidth = '0px';
        this.cardStylesConfig.borderColor = 'transparent';
        this.cardStylesConfig.borderStyle = 'none';
        this.cardStylesConfig.borderRadius = this.defaultCardStylesConfig.borderRadius;
      }
    }
  }

  setCustomCardStyle(): void {
    if (this.customCardStylesConfig) {
      this.customCardStylesConfig.borderWidth && (this.defaultCardStylesConfig.borderWidth = this.cardStylesConfig.borderWidth = this.customCardStylesConfig.borderWidth);
      this.customCardStylesConfig.borderColor && (this.defaultCardStylesConfig.borderColor = this.cardStylesConfig.borderColor = this.customCardStylesConfig.borderColor);
      this.customCardStylesConfig.borderStyle && (this.defaultCardStylesConfig.borderStyle = this.cardStylesConfig.borderStyle = this.customCardStylesConfig.borderStyle);
      this.customCardStylesConfig.borderRadius && (this.defaultCardStylesConfig.borderRadius = this.cardStylesConfig.borderRadius = this.customCardStylesConfig.borderRadius);
    }
  }

  onCardClick(): void {
    this.cardClickEvent.emit();
  }
}
