import * as Sentry from "@sentry/angular-ivy";
import { environment } from "./environments/environment";

export const SentryConfig: Sentry.BrowserOptions = {
  environment: 'production',
  release: environment.version,
  dsn: "https://9d7bcbf13263939d931c5312023e152a@o4506308503273472.ingest.sentry.io/4506465916157952",
  ignoreErrors: [],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['https://dashboard.calenso.com', 'https://my.calenso.com/api', 'https://auth.calenso.com/api'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.8,
};
