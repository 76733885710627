export const WINDOW_LISTENER_CONSTANTS = {
  BOOT: 'BOOT',
  CONFIG_DATA: 'CONFIG_DATA',
  INIT_CONFIG: 'INIT_CONFIG',
  PROGRESSBAR_COUNT_EVENT: 'PROGRESSBAR_COUNT_EVENT',
  LANGUAGE_CHANGED_EVENT: 'LANGUAGE_CHANGED_EVENT',
  APPOINTMENT_BOOKING_DONE: 'APPOINTMENT_BOOKING_DONE',
  PARENT_TO_CHILD_WEBSITE_EVENT: 'PARENT_TO_CHILD_WEBSITE_EVENT',
  PARENT_BOOKING_WEBSITE_EVENT: 'PARENT_BOOKING_WEBSITE_EVENT',
  CALENSO_CHILD_TO_PARENT_EVENT: 'CALENSO_CHILD_TO_PARENT_EVENT',
};
