import {Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {WorkerDbModel} from 'src/app/models/worker-db.model';
import {LOCAL_STORAGE_CONSTANTS} from '../../constants/local-storage.constants';
import {CryptoService} from '../utils/crypto.service';
import {HttpClientService} from '../utils/http-client.service';
import {LocalstorageService} from '../utils/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  constructor(
    private httpClientService: HttpClientService,
    private localstorageService: LocalstorageService,
    private cryptoService: CryptoService
  ) { }

  getWorkers(): Observable<WorkerDbModel[]> {
    const workers = this.getWorkersFromLocalStorage();
    if (workers) {
      return of(workers);
    }

    return this.httpClientService.get('workers', {}).pipe(
      switchMap(workers => {
        this.setWorkersInLocalStorage(workers);
        return of(workers);
      })
    );
  }

  getWorkersFromLocalStorage(): WorkerDbModel[] {
    const encryptedWorkers = this.localstorageService.getItem(LOCAL_STORAGE_CONSTANTS.WORKERS_KEY) as string;
    if (encryptedWorkers) {
      const decryptedWorkers = this.cryptoService.decryptValue(encryptedWorkers) as string;
      return JSON.parse(decryptedWorkers) as WorkerDbModel[];
    }
    return null;
  }

  setWorkersInLocalStorage(workers: WorkerDbModel[]): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(workers)
    );
    this.localstorageService.setItem(LOCAL_STORAGE_CONSTANTS.WORKERS_KEY, encryptedString);
  }
}
