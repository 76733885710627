export const LOCAL_STORAGE_CONSTANTS = {
  WORKERS_KEY: 'wk',
  CALENSO_AUTH_TOKEN: 'csw_cat'
};

export const BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS = {
  BOOKING_CUSTOM_FIELD: 'sw_booking_custom_field_',
  BOOKING_APPOINTMENT_STATE: 'sw_booking_appointment_state_',
  BOOKING_APPOINTMENT_SELECTED_USER: 'sw_booking_appointment_selected_user_',
  BOOKING_APPOINTMENT_CART_ITEMS: 'sw_booking_appointment_cart_items_',
};
