<form #bookingQuestionForm="ngForm" #bookingQuestion>
  <ng-template #recursiveListTmpl let-customFieldDependencies="customFieldDependencies">
    @for (dependencyCustomField of customFieldDependencies; track dependencyCustomField) {
      <div>
        <app-booking-question
          [customField]="dependencyCustomField"
          [selectedCustomFieldValues]="bookingService?.selectedBookingData?.selectedCustomFieldValues"
          (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
          [isNextButtonPressed]="isNextButtonPressed"
          (changeCustomFieldSelectedValueEvent)="customFieldChangeDetection($event)"
        />
        @if (dependencyCustomField?.customFieldDependencies?.length > 0) {
          <div>
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ customFieldDependencies: dependencyCustomField?.customFieldDependencies }" />
          </div>
        }
      </div>
    }
  </ng-template>
  <app-booking-question
    [customField]="widgetQuestion"
    [selectedCustomFieldValues]="bookingService?.selectedBookingData?.selectedCustomFieldValues"
    [isNextButtonPressed]="isNextButtonPressed"
    (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
    (changeCustomFieldSelectedValueEvent)="customFieldChangeDetection($event)"
  />
  @if (widgetQuestion?.customFieldDependencies?.length > 0) {
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ customFieldDependencies: widgetQuestion?.customFieldDependencies }" />
  }
  @if (showDependenciesGhostElement) {
    <div class="skeleton-box-card mt-4">
      <div class="">
        <div class="row">
          @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
            <div class="col-md-12 mb-4">
              <div class="d-flex stats-card shadow">
                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                  <div class="">
                    <div class="stat-box-1"></div>
                    <div class="stat-box mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }
  @if (!showDependenciesGhostElement) {
    <div class="d-flex justify-content-end mt-4 cbw-custom-navigation">
      @if (widgetQuestion?.is_end_of_process !== 1 && !isEndOfProcessByChildDependencies) {
        <div class="me-2">
          <app-button
            id="{{ 'booking-questions-previous-btn' }}"
            [isDisabled]="isFirst && widgetService?.widgetConf?.context?.show_questions_before_booking_process"
            (clickEvent)="previousQuestion()"
            [fontColor]="widgetService?.widgetConf?.button_font_color"
            [backgroundColor]="widgetService?.widgetConf?.button_background_color"
          >
            <span class="custom-button-icon back"></span>
            {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 129, type: 'template' }) | translation: 'template_content': lang )}}
          </app-button>
        </div>
        <div>
          @if (!isLast) {
            <app-button
              id="{{ 'booking-questions-next-question-btn' }}"
              (clickEvent)="nextQuestion()"
              [fontColor]="widgetService?.widgetConf?.button_font_color"
              [backgroundColor]="widgetService?.widgetConf?.button_background_color"
            >
              {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 128, type: 'template' }) | translation: 'template_content': lang )}}
              <span class="custom-button-icon next"></span>
            </app-button>
          }
          @if (isLast) {
            <app-button
              id="{{ 'booking-questions-finish-last-btn' }}"
              (clickEvent)="finishLastBookingQuestion()"
              [fontColor]="widgetService?.widgetConf?.button_font_color"
              [backgroundColor]="widgetService?.widgetConf?.button_background_color"
            >
              {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 128, type: 'template' }) | translation: 'template_content': lang )}}
              <span class="custom-button-icon next"></span>
            </app-button>
          }
        </div>
      }
    </div>
  }
</form>
