<div
  class="calendar-loader"
  [ngStyle]="{ display: disableAllDays ? 'flex' : 'none' }"
>
  <app-loader />
</div>
<div
  class="week-days"
  [ngStyle]="{ display: !disableAllDays ? 'block' : 'none' }"
>
  <!--Calendar subheader week days in shot form (Mo, Tu)-->
  <ng-template #shortDaysHeaderTemplate>
    <div class="cal-cell-row cal-header">
      @for (day of columnHeaders | slice:0:7; track day) {
        <div
          class="cal-cell"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          [ngClass]="day.cssClass"
        >
          {{ "calendar_component.day" + day.date.getDay() | translate }}
        </div>
      }
    </div>
  </ng-template>
  <!-- Calendar cell -->
  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
      @if (day.cssClass !== 'cal-disabled') {
        <span class="cal-day-number text-center" (click)="onSelectDay(day)">
          {{ day.date | calendarDate : "monthViewDayNumber" : locale }}
          @if (day.badgeTotal > 0 && day.cssClass === 'cal-selected') {
            <span
              class="badge badge-primary custom-selected-date-slots-badge"
              [ngClass]="{
                'selected-date-single-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) < 10,
                'selected-date-double-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) >= 10
              }"
            >{{ day.badgeTotal }}</span>
          } @if (day.badgeTotal > 0 && day.cssClass === 'cal-selected-without-highlight') {
            <span
              class="badge badge-primary custom-slots-badge-without-highlighting"
              [ngClass]="{
                'without-highlight-single-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) < 10,
                'without-highlight-double-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) >= 10
              }"
            >{{ day.badgeTotal }}</span>
          }
        </span>
      }
      @if (day.cssClass === 'cal-disabled') {
        <span class="cal-day-number text-center disabled-day">
          {{ day.date | calendarDate : "monthViewDayNumber" : locale }}
        </span>
      }
    </div>
  </ng-template>
  <!-- Calendar Header -> Previous button, Month & Title year and Next button-->
  <div class="btn-group d-flex calendar-header justify-content-between align-items-center custom-calendar-header">
    <button
      class="btn btn-link calendar-header-title"
      [ngClass]="{ 'disable-button': prevBtnDisabled }"
      mwlCalendarPreviousView
      view="month"
      [(viewDate)]="viewDate"
      [disabled]="prevBtnDisabled"
      (click)="decrement()"
    >
      <img
        class="calendar-icons"
        src="{{ imagePath }}/img/icons/chevron-left-solid.svg"
        alt=""
      />
      <!-- TODO add icon <app-calio-icon [iconType]="'chevron-left'" [customClass]="'fa-2x'"></app-calio-icon>-->
    </button>
    <div class="btn btn-link calendar-header-title disabled">
      <div class="calendar-header-button-text text-overflow-center">
        <div>{{ viewDate | date : "MMMM" | translate }}</div>
        <div>{{ viewDate | date : "y" }}</div>
      </div>
    </div>
    <button
      class="btn btn-link calendar-header-title"
      [ngClass]="{ 'disable-button': nextBtnDisabled }"
      mwlCalendarNextView
      view="month"
      [(viewDate)]="viewDate"
      [disabled]="nextBtnDisabled"
      (click)="increment()"
    >
      <img
        class="calendar-icons"
        src="{{ imagePath }}/img/icons/chevron-right-solid.svg"
        alt="chevron-right-solid"
      />
      <!-- TODO add icon <app-calio-icon [iconType]="'chevron-right'" [customClass]="'fa-2x'"></app-calio-icon>-->
    </button>
  </div>
  <div>
    <mwl-calendar-month-view
      [viewDate]="viewDate"
      [weekStartsOn]="1"
      [headerTemplate]="shortDaysHeaderTemplate"
      [refresh]="refreshCalendar"
      [cellTemplate]="customCellTemplate"
      (beforeViewRender)="beforeMonthViewRender($event)"
    />
  </div>
</div>
