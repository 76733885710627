@for ( widgetQuestion of currentSurveyStep?.booking_questions; track widgetQuestion; let index = $index; let isFirst = $first; let isLast = $last; let count = $count) {
  <app-survey-question-view
    [prefix]="currentSurveyStep.step_number + '.' + (index + 1)"
    [surveyGlobalStep]="currentSurveyStep.step_number"
    [widgetQuestion]="widgetQuestion"
    [currentIndex]="index"
    [currentSurveyStep]="currentSurveyStep"
    [isFirst]="isFirst"
  />
}
