import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DATE_ISO_FORMAT } from '@constants/date.constants';
import { BookingQuestionsComponent } from '@features/lead-generator/components/booking-questions/booking-questions.component';
import { LeadForm } from '@models/lead.model';
import { UserInfo } from '@models/user.model';
import { WidgetConfModel } from '@models/widget-conf.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { WorkerDbModel } from '@models/worker-db.model';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { BookingService } from '@services/feature/booking.service';
import { LeadGeneratorService } from '@services/feature/lead-generator.service';
import { WidgetService } from '@services/feature/widget.service';
import { LoggerService } from '@services/utils/logger.service';
import { SmartwidgetEventsService } from '@services/utils/smartwidget-events.service';
import { AlertComponent } from '@ui-lib/alert/alert.component';
import * as moment from 'moment';

@Component({
  selector: 'app-lead-generator',
  templateUrl: './lead-generator.component.html',
  styleUrls: ['./lead-generator.component.scss'],
  standalone: true,
  imports: [BookingQuestionsComponent, AlertComponent, CalioSafePipe]
})
export class LeadGeneratorComponent implements OnInit {

  readonly dateIsoFormat = DATE_ISO_FORMAT;

  showQuestionsScreen = true;
  showAlertScreen = false;
  alertType: string;
  alertMessage: string;
  isAlertMessageTranslated = false;
  widgetTemplates: WidgetTemplateModel[] = [];

  constructor(
    public widgetService: WidgetService,
    private smartwidgetEventsService: SmartwidgetEventsService,
    private leadGeneratorService: LeadGeneratorService,
    private bookingService: BookingService
  ) {}

  ngOnInit(): void {
    this.getWidgetTemplates();
    const config: WidgetConfModel = this.widgetService.widgetConf;
    if (config.trigger_message) {
      this.smartwidgetEventsService.triggerTeaserEvent.emit({
        message: config.trigger_message,
        isPredefined: false,
      });
    }

    if (!config?.context?.customer || config?.context?.customer.length === 0) {
      this.setupEmptyCustomerArrayErrorMessage();
    }
  }

  onFinalSuccess(): void {
    this.showQuestionsScreen = false;
    this.isAlertMessageTranslated = true;
    this.alertType = 'success';
    this.alertMessage = 'success_messages.lead_success';
    this.showAlertScreen = true;
  }

  onFinalError(): void {
    this.showQuestionsScreen = false;
    this.isAlertMessageTranslated = true;
    this.alertType = 'danger';
    this.alertMessage = 'failed_messages.lead_failed';
    this.showAlertScreen = true;
  }

  setupEmptyCustomerArrayErrorMessage(): void {
    this.showQuestionsScreen = false;
    this.isAlertMessageTranslated = false;
    this.alertType = 'danger';
    this.alertMessage = 'Customer array cannot be empty.';
    this.showAlertScreen = true;
  }

  setupEmptyBookingQuestionArrayMessage(): void {
    this.showQuestionsScreen = false;
    this.isAlertMessageTranslated = false;
    this.alertType = 'danger';
    this.alertMessage =
      'You have to ask at least one question to the customer.';
    this.showAlertScreen = true;
  }

  onSubmitEvent(event: {
    userInfo: UserInfo;
    customFieldValues: {
      custom_field_id: number;
      type: string;
      value?: any;
      file_ids?: any;
    }[];
    selectedWorker: WorkerDbModel;
  }): void {
    const leadForm = new LeadForm();
    leadForm.prename = event.userInfo.forename;
    leadForm.lastname = event.userInfo.lastName;
    leadForm.email = event.userInfo.eMail;
    leadForm.phone = event.userInfo.phone;
    leadForm.mobile = event.userInfo.mobile;
    leadForm.customer_type_id =
      this.widgetService.widgetConf.context.customer_type_id;
    leadForm.customFieldValues = event.customFieldValues;
    leadForm.street = event.userInfo.street;
    leadForm.zip = event.userInfo.zip;
    leadForm.city = event.userInfo.city;
    leadForm.company_name = event.userInfo?.company;
    leadForm.title = event.userInfo?.title;
    leadForm.country = event.userInfo?.country;
    leadForm.birthday = event.userInfo?.birthday ? moment(event.userInfo?.birthday).format(this.dateIsoFormat) : undefined;
    leadForm.widget_uuid = this.widgetService.widgetConf.widget_uuid;

    event?.selectedWorker && (leadForm.worker_id = event.selectedWorker.id);
    this.widgetService?.widgetWorkerData?.uuid &&
      (leadForm.worker_uuid = this.widgetService?.widgetWorkerData?.uuid);

    this.leadGeneratorService.createLead(leadForm).subscribe({
      next: (result) => {
        if (result?.customer) {
          this.onFinalSuccess();
        } else {
          this.onFinalError();
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        this.onFinalError();
      },
    });
  }

  getWidgetTemplates(): void {
    this.leadGeneratorService.getWidgetTemplates().subscribe({
      next: (templates: WidgetTemplateModel[]) => {
        this.widgetTemplates = templates;
        this.leadGeneratorService.widgetTemplates = this.widgetTemplates;
        this.bookingService.widgetTemplates = this.widgetTemplates;
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      },
    });
  }
}
