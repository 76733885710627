<input
  type="text"
  class="calio-tel-input"
  [ngClass]="cssClass"
  [attr.id]="name"
  [attr.name]="name"
  [name]="name"
  [formControl]="telInputControl"
  (countrychange)="i18nizePhoneNumber()"
  (click)="onClick()"
  (blur)="onBlur()"
  #intlTelInput
/>
