import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.component.html',
    styleUrls: ['./image-modal.component.scss'],
    standalone: true,
})

export class ImageModalComponent implements OnInit {
  imageUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string }
  ) {
    this.imageUrl = data.imageUrl;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}
