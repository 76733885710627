import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {CssOverwrites} from '../../models/css-overwrites.model';

@Injectable({
  providedIn: 'root'
})
export class DomManipulationService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  setCustomNextPreviewButtonStyle(id: string): void {
    const style = document.createElement('style');
    style.textContent = `#${id} { display: none; }`;
    document.head.appendChild(style);
  }

  applyCustomCss(css: CssOverwrites): void {
    const style = document.createElement('style');
    style.setAttribute('scoped', '');
    style.innerHTML = css.code;
    this.document.body.append(style);
  }
}
