import { NgClass, NgStyle } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-csw-link',
  templateUrl: './csw-link.component.html',
  styleUrls: ['./csw-link.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle],
})

export class CswLinkComponent {
  @Input({ transform: booleanAttribute }) isLink = true;
  @Input({ transform: booleanAttribute }) showUnderLine = false;
  @Input() target = 'blank';
  @Input() text: string;
  @Input() link: string;
  @Input() styleConfig: {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    textDecorationColor?: string;
  } = {};

  @Output() clickEvent = new EventEmitter<void>();

  protected onLinkClick(): void {
    this.clickEvent.emit();
  }
}
