@if (isAlertMessageTranslated) {
  <div class="alert alert-{{ type }}" role="alert">
    {{ message | translate }}
  </div>
}
@if (!isAlertMessageTranslated) {
  <div class="alert alert-{{ type }}" role="alert">
    {{ message }}
  </div>
}
