import { CurrencyPipe, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideAnimations
} from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthTokenInterceptor } from '@interceptor/auth-token.interceptor';
import { GlobalErrorHandler } from '@interceptor/global-error-handler.interceptor';
import { HttpInterceptor } from '@interceptor/http-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { CalendarModule, CalendarUtils, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFrCH, 'fr_CH');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeItCh, 'it_CH');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeSv, 'sv');

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      RouterModule.forRoot([])
    ),
    {
      provide: LOCALE_ID,
      useValue: 'de-ch',
    },
    CalendarUtils,
    CurrencyPipe,
    provideAnimations(),
    provideHttpClient(
      withInterceptors([HttpInterceptor, AuthTokenInterceptor])
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true, }
  ],
};
