export class LeadForm {
  prename?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  customer_type_id?: number;
  customFieldValues: {
    custom_field_id: number,
    type: string,
    value?: any,
    file_ids?: any
  }[];
  street?: string;
  zip?: string;
  city?: string;
  worker_id: number;
  worker_uuid: string;
  country?: number;
  policy?: boolean;
  company?: string;
  title?: number;
  widget_uuid?: string;
  company_name?: string;
  birthday?: string;
}
